import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FormContainer from '../../../components/Forms/FormContainer';
import IconList from '../../../components/IconList/IconList';
import TextInput from '../../../components/Inputs/TextInput';
import Loading from '../../../components/Loading/Loading';
import Title from '../../../components/Title/Title';
import { tFormKey } from '../../../helpers/translate';
import { AcademyFormatedData } from '../../../redux/academy/definitions';
import { selectAcademyFormatedData, selectTypeList } from '../../../redux/academy/selectors';
import { IdCodeColor } from '../../../redux/common/definitions';
import { AppState } from '../../../redux/root-reducer';
import { scholarshipGetMetadata } from '../../../redux/scholarship/actions';
import styles from './academyDetails.module.scss';

interface AcademyDetailsProps {
  academyData: AcademyFormatedData;
  loading: boolean;
  educationalTypes: IdCodeColor[];
  loaded: boolean;
  onSelectComponent: (componentId: number) => void;
  scholarshipGetMetadata: () => void;
}

const UnConnectedAcademyDetails: React.FC<AcademyDetailsProps> = ({
  academyData,
  loading,
  educationalTypes,
  loaded,
  scholarshipGetMetadata,
}) => {
  useEffect(() => {
    loaded || scholarshipGetMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading big />;
  return (
    <form className={styles.form}>
      <Title>{tFormKey('Detalle del Centro Educativo')}</Title>
      <FormContainer title={tFormKey('Datos del centro')}>
        <TextInput
          size="50"
          defaultValue={academyData?.code}
          name="code"
          label={tFormKey('Código del centro')}
          disabled
        />
        <TextInput
          size="50"
          defaultValue={academyData?.name}
          name="name"
          label={tFormKey('Nombre de Instituto')}
          disabled
        />
        <TextInput
          size="50"
          defaultValue={academyData?.address}
          name="address"
          label={tFormKey('Dirección')}
          disabled
        />
        <TextInput
          size="50"
          defaultValue={academyData?.zip_code}
          name="zip_code"
          label={tFormKey('Código Postal')}
          disabled
        />
        <TextInput size="50" defaultValue={academyData?.town} name="town" label={tFormKey('Ciudad')} disabled />
        <TextInput
          size="50"
          defaultValue={academyData?.nature}
          name="nature"
          label={tFormKey('Naturaleza de la entidad')}
          disabled
        />
        <TextInput size="50" defaultValue={academyData?.region} name="region" label={tFormKey('Comarca')} disabled />
        <TextInput
          size="50"
          defaultValue={academyData?.municipality}
          name="municipality"
          label={tFormKey('Municipio')}
          disabled
        />
        <TextInput
          size="50"
          defaultValue={academyData?.territorial_service}
          name="territorial_service"
          label={tFormKey('Servicio Territorial')}
          disabled
        />
        <TextInput size="50" defaultValue={academyData?.phone} name="phone" label={tFormKey('Teléfono')} disabled />
        <TextInput size="50" defaultValue={academyData?.fax} name="fax" label={tFormKey('Fax')} disabled />
        <TextInput size="50" defaultValue={academyData?.email} name="email" label={tFormKey('Email')} disabled />

        <IconList title={tFormKey('Tipo de centro')} types={educationalTypes} codes={academyData.educational_codes} />
      </FormContainer>
    </form>
  );
};

const AcademyDetails = connect(
  (state: AppState) => ({
    academyData: selectAcademyFormatedData(state),
    loading: state.academyReducer.loading,
    educationalTypes: selectTypeList(state),
    loaded: !!Object.keys(state.scholarshipReducer.scholarshipMetadata).length,
  }),
  (dispatch: any) => ({
    scholarshipGetMetadata: (): void => dispatch(scholarshipGetMetadata()),
  }),
)(UnConnectedAcademyDetails);

export default AcademyDetails;
