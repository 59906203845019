import { put, takeLatest } from '@redux-saga/core/effects';
import {
  academyUpdateContact,
  addAcademyIbanApi,
  createAcademyContact,
  downloadIbanDocumentFromApi,
  editAcademiesIbansApi,
  editAcademyIbanApi,
  getAcademyContact,
  getAcademyContactsList,
  getAcademyDataApi,
  getAcademyIbanApi,
  launchImportAcademies,
  listAcademiesIbans,
  removeAcademyIbanApi,
  removeContactAcademy,
  removeIbanDocumentFromApi,
  replaceAcademyContactsList,
  uploadIbanDocumentFromApi,
} from '../../api/academy';
import { Await } from '../../api/api';
import errorMessage from '../../helpers/errorMessage';
import { tKey } from '../../helpers/translate';
import { showSnackbar } from '../FeedbackAPI/actions';
import { AcademyDataConsts } from './action_types';
import {
  academyContactKo,
  academyContactOk,
  academyContactsListKo,
  academyContactsListOk,
  academyUpdateContactKo,
  academyUpdateContactOk,
  academyValidationErrors,
  addAcademyIbanKo,
  addAcademyIbanOk,
  createAcademyContactKo,
  createAcademyContactOk,
  downloadIbanDocumentKo,
  downloadIbanDocumentOk,
  editAcademyIbanKo,
  editAcademyIbanOk,
  editBulkAcademyIbanKo,
  getAcademyDataKo,
  getAcademyDataOk,
  getAcademyIbanKo,
  getAcademyIbanOk,
  importAcademiesKo,
  listAcademyIbansKo,
  listAcademyIbansOk,
  removeAcademyIbanKo,
  removeAcademyIbanOk,
  removeContactAcademyKo,
  removeContactAcademyOk,
  removeIbanDocumentKo,
  removeIbanDocumentOk,
  replaceAcademyContactsListKo,
  replaceAcademyContactsListOk,
  uploadIbanDocumentKo,
  uploadIbanDocumentOk,
} from './actions';
import {
  AcademyContactAction,
  AcademyContactsListAction,
  AcademyUpdateContactAction,
  AddAcademyIbanAction,
  CreateAcademyContactAction,
  DownloadIbanDocumentAction,
  EditAcademyIbanAction,
  EditBulkAcademyIbanAction,
  GetAcademyDataAction,
  GetAcademyIbanAction,
  ImportAcademiesAction,
  ListAcademyIbansAction,
  RemoveAcademyIbanAction,
  RemoveContactAcademyAction,
  RemoveIbanDocumentAction,
  ReplaceAcademyContactListAction,
  UploadIbanDocumentAction,
} from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getAcademyDataFromSaga(action: GetAcademyDataAction): Generator<any, void, any> {
  try {
    const { data } = yield getAcademyDataApi(action.id);
    yield put(getAcademyDataOk(data));
  } catch (e) {
    yield put(getAcademyDataKo(errorMessage(e)));
    yield put(showSnackbar(tKey(`Hubo un error al recuperar los datos.`), 'error', undefined, 1500));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* addAcademyIbanFromSaga(action: AddAcademyIbanAction): Generator<any, void, any> {
  try {
    const result = (yield addAcademyIbanApi(action.academyIban, action.academyId)) as Await<
      ReturnType<typeof addAcademyIbanApi>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(addAcademyIbanOk(data));
        yield put(showSnackbar(tKey('IBAN añadido correctamente'), 'success', undefined, 1500));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(academyValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(addAcademyIbanKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* editAcademyIbanFromSaga(action: EditAcademyIbanAction): Generator<any, void, any> {
  try {
    const result = (yield editAcademyIbanApi(action.academyIban, action.academyId)) as Await<
      ReturnType<typeof editAcademyIbanApi>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(editAcademyIbanOk(data));
        yield put(showSnackbar(tKey('IBAN editado correctamente'), 'success', undefined, 1500));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(academyValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(editAcademyIbanKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* listAcademiesIbansFromSaga(action: ListAcademyIbansAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const data = yield listAcademiesIbans(id);
    yield put(listAcademyIbansOk(data));
  } catch (e) {
    yield put(listAcademyIbansKo(errorMessage(e)));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getAcademyIbanFromSaga(action: GetAcademyIbanAction): Generator<any, void, any> {
  try {
    const { academyId, academyIbanId } = action;
    const { data } = yield getAcademyIbanApi(academyId, academyIbanId);
    yield put(getAcademyIbanOk(data));
  } catch (e) {
    yield put(getAcademyIbanKo(errorMessage(e)));
  }
}

function* removeAcademyIbanFromSaga(action: RemoveAcademyIbanAction): Generator<any, void, any> {
  try {
    const { academyId, academyIbanId } = action;
    yield removeAcademyIbanApi(academyId, academyIbanId);
    yield put(removeAcademyIbanOk(academyIbanId));
    yield put(showSnackbar('Iban suprimido correctamente', 'success'));
  } catch (e) {
    yield put(removeAcademyIbanKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al suprimir el iban', 'error'));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* uploadIbanDocumentFromSaga(action: UploadIbanDocumentAction): Generator<any, void, any> {
  try {
    const { file } = action;
    const fileData = yield uploadIbanDocumentFromApi(file);
    yield put(uploadIbanDocumentOk(fileData));
    yield put(showSnackbar('Documento IBAN subido correctamente', 'success'));
  } catch (e) {
    yield put(uploadIbanDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el documento', 'error'));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* removeIbanDocumentFromSaga(action: RemoveIbanDocumentAction): Generator<any, void, any> {
  try {
    const { file_id } = action;
    yield removeIbanDocumentFromApi(file_id);
    yield put(removeIbanDocumentOk());
  } catch (e) {
    yield put(removeIbanDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al borrar el archivo', 'error'));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* downloadIbanDocumentFromSaga(action: DownloadIbanDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, filename } = action;
    const data = yield downloadIbanDocumentFromApi(file_id);
    yield put(downloadIbanDocumentOk(data, filename));
  } catch (e) {
    yield put(downloadIbanDocumentKo(errorMessage(e)));
  }
}

function* editBulkAcademyIbanFromSaga(action: EditBulkAcademyIbanAction): Generator<any, void, any> {
  try {
    yield editAcademiesIbansApi(action.academiesIbans);
    yield put(showSnackbar(tKey("IBAN'S actualizados correctamente"), 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(tKey('Ha ocurrido algún problema al editar el IBAN'), 'error', undefined, 1500));
    yield put(editBulkAcademyIbanKo(errorMessage(e)));
  }
}

function* getAcademyContactsListFromSaga(action: AcademyContactsListAction): Generator<any, void, any> {
  try {
    const { academy_id } = action;
    const data = yield getAcademyContactsList(academy_id);
    yield put(academyContactsListOk(data));
  } catch (e) {
    yield put(academyContactsListKo(errorMessage(e)));
  }
}

function* getAcademyContactFromSaga(action: AcademyContactAction): Generator<any, void, any> {
  try {
    const { academy_id, staff_id } = action;
    const data = yield getAcademyContact(academy_id, staff_id);
    yield put(academyContactOk(data));
  } catch (e) {
    yield put(academyContactKo(errorMessage(e)));
  }
}

function* createAcademyContactFromSaga(action: CreateAcademyContactAction): Generator<any, void, any> {
  try {
    const { contactData, academy_id } = action;
    const data = yield createAcademyContact(contactData, academy_id);
    yield put(createAcademyContactOk(data));
    yield put(showSnackbar('Responsable añadido', 'success'));
  } catch (e) {
    yield put(createAcademyContactKo(errorMessage(e)));
    yield put(showSnackbar('El usuario no se ha podido añadir como responsable', 'error'));
  }
}

function* updateAcademyContactFromSaga(action: AcademyUpdateContactAction): Generator<any, void, any> {
  try {
    const { contactInfo, academy_id } = action;
    const data = yield academyUpdateContact(contactInfo, academy_id);
    yield put(academyUpdateContactOk(data));
    yield put(showSnackbar('Contacto actualizado', 'success'));
  } catch (e) {
    yield put(academyUpdateContactKo(errorMessage(e)));
    yield put(showSnackbar('El usuario no se ha podido añadir como responsable', 'error'));
  }
}

function* removeContactAcademyFromSaga(action: RemoveContactAcademyAction): Generator<any, void, any> {
  try {
    const { academy_id, staff_id } = action;
    yield removeContactAcademy(academy_id, staff_id);
    yield put(removeContactAcademyOk(staff_id));
  } catch (e) {
    yield put(removeContactAcademyKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al suprimir', 'error'));
  }
}
function* replaceAcademyContactsListFromSaga(action: ReplaceAcademyContactListAction): Generator<any, void, any> {
  try {
    const { dataList, academy_id } = action;
    const data = yield replaceAcademyContactsList(dataList, academy_id);
    yield put(replaceAcademyContactsListOk(data));
    yield put(showSnackbar('Lista de contactos actualizada', 'success'));
  } catch (e) {
    yield put(replaceAcademyContactsListKo(errorMessage(e)));
    yield put(showSnackbar('La lista de contactos no se ha podido actualizar', 'error'));
  }
}

function* importAcademiesFromSaga(action: ImportAcademiesAction): Generator<any, void, any> {
  try {
    const { user_id } = action;
    yield launchImportAcademies(user_id);
  } catch (e) {
    yield put(importAcademiesKo(errorMessage(e)));
  }
}

export default [
  takeLatest<GetAcademyDataAction>(AcademyDataConsts.GET_ACADEMY_DATA, getAcademyDataFromSaga),
  takeLatest<ImportAcademiesAction>(AcademyDataConsts.IMPORT_ACADEMIES, importAcademiesFromSaga),
  takeLatest<AcademyContactsListAction>(AcademyDataConsts.GET_ACADEMY_CONTACTS, getAcademyContactsListFromSaga),
  takeLatest<AcademyContactAction>(AcademyDataConsts.GET_ACADEMY_CONTACT, getAcademyContactFromSaga),
  takeLatest<ReplaceAcademyContactListAction>(
    AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST,
    replaceAcademyContactsListFromSaga,
  ),
  takeLatest<CreateAcademyContactAction>(AcademyDataConsts.ADD_ACADEMY_CONTACT, createAcademyContactFromSaga),
  takeLatest<RemoveContactAcademyAction>(AcademyDataConsts.REMOVE_ACADEMY_CONTACT, removeContactAcademyFromSaga),
  takeLatest<AcademyUpdateContactAction>(AcademyDataConsts.UPDATE_ACADEMY_CONTACT, updateAcademyContactFromSaga),
  takeLatest<AddAcademyIbanAction>(AcademyDataConsts.ADD_ACADEMY_IBAN, addAcademyIbanFromSaga),
  takeLatest<EditAcademyIbanAction>(AcademyDataConsts.EDIT_ACADEMY_IBAN, editAcademyIbanFromSaga),
  takeLatest<GetAcademyIbanAction>(AcademyDataConsts.GET_ACADEMY_IBAN, getAcademyIbanFromSaga),
  takeLatest<RemoveAcademyIbanAction>(AcademyDataConsts.REMOVE_ACADEMY_IBAN, removeAcademyIbanFromSaga),
  takeLatest<ListAcademyIbansAction>(AcademyDataConsts.LIST_ACADEMY_IBANS, listAcademiesIbansFromSaga),
  takeLatest<EditBulkAcademyIbanAction>(AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN, editBulkAcademyIbanFromSaga),
  takeLatest<UploadIbanDocumentAction>(AcademyDataConsts.UPLOAD_IBAN_DOCUMENT, uploadIbanDocumentFromSaga),
  takeLatest<RemoveIbanDocumentAction>(AcademyDataConsts.REMOVE_IBAN_DOCUMENT, removeIbanDocumentFromSaga),
  takeLatest<DownloadIbanDocumentAction>(AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT, downloadIbanDocumentFromSaga),
];
