import { Query } from 'material-table';

type ColumnFilterItem = {
  field: string;
  value: string;
};

class Pagination {
  private constructor(
    private page_size: number,
    private page: number,
    private order_by: string | null = null,
    private order_type: 'asc' | 'desc' = 'asc',
    private columnFilters: ColumnFilterItem[] = [],
  ) {}

  public static createDefault(): Pagination {
    return new Pagination(50, 1);
  }

  public static createFromQuery(query: Query): Pagination {
    const { pageSize, page, orderBy, orderDirection, filters } = query;
    const order_by = orderBy ? orderBy.field : null;
    const order_type = orderDirection || 'asc';
    const columFilters =
      filters && filters.length > 0
        ? filters.map(element => ({
            field: element.column.field,
            value: element.value,
          }))
        : [];
    return new Pagination(pageSize, page + 1, order_by, order_type, columFilters);
  }

  private getOrderBy(): string {
    if (!this.order_by) {
      return '';
    }
    return `&order_by=${this.order_by}&order_type=${this.order_type}`;
  }

  private getFilters(): string {
    if (this.columnFilters.length === 0) {
      return '';
    }
    const queryParams = encodeURIComponent(JSON.stringify(this.columnFilters));
    return `&filters=${queryParams}`;
  }

  public toParamString(): string {
    const orderBy = this.getOrderBy();
    const filters = this.getFilters();
    return `?page_size=${this.page_size}&page=${this.page}${orderBy}${filters}`;
  }
}

export default Pagination;
