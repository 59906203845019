/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, InputLabel, MenuItem } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../../api/api';
import CheckboxCompo from '../../../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../../components/RadioButtons/RadioButtonGroup';
import { RadioOptions, yesNoOptions } from '../../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../../components/Select/SelectController';
import Title from '../../../../../components/Title/Title';
import FncAddDocuments from '../../../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../../helpers/customHooks/useValidatorAPI';
import { tKey, tScholarshipKey } from '../../../../../helpers/translate';
import { validateFields } from '../../../../../helpers/validator';
import { AcademySelectItem } from '../../../../../redux/academy/definitions';
import { selectIsNousCims } from '../../../../../redux/auth/selectors';
import { CodeNamePair, DocumentationItem, IdNamePair } from '../../../../../redux/common/definitions';
import { AppState } from '../../../../../redux/root-reducer';
import {
  scholarshipSetEducationalInfoData,
  scholarshipSubmitInformation,
} from '../../../../../redux/scholarship/actions';
import {
  ScholarshipCurrentSituationCodes,
  ScholarshipData,
  ScholarshipMetadata,
  ScholarshipSteps,
  ScholarshipType,
} from '../../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentationPerCode,
  selectGetScholarshipMetadata,
  selectScholarshipValidationErrors,
} from '../../../../../redux/scholarship/selectors';
import { schoolEducationalValoration } from '../../../../../validations/scholarshipSchema';
import StudyCenterSelector from '../StudyCenterSelector';
import styles from '../requestForms.module.scss';
import EducationalValorationCourseForm from './EducationalValorationCourseForm';
import EducationalValorationLastCourseForm from './EducationalValorationLastCourseForm';
import { selectGenericMetadata } from '../../../../../redux/metadata/selectors';

interface EducationalValorationFormProps {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  provinces: CodeNamePair[];
  documentation: DocumentationItem[];
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  validationErrors: APIValidationError | null;
  isNousCims?: boolean;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
  setData: (key: string, value: string | number | null) => void;
  saveData: (data: ScholarshipData) => void;
}

const EducationalValorationForm: React.FC<EducationalValorationFormProps> = ({
  scholarshipData,
  scholarshipMetadata,
  provinces,
  documentation,
  readOnly,
  buttonActionSave,
  validationErrors,
  isNousCims,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
  setData,
  saveData,
}) => {
  const {
    id,
    follow_up_type,
    current_situation,
    other_reasons,
    requested_academy_code,
    requested_academy_name,
    scholarship_academy_choose_reason_id,
    educational_type_code,
    motivational_program,
    mentoring_program,
    vocational_workshop,
    individual_coaching,
    isEntity,
    is_renewal,
    scholarshipType,
    parents_with_post_compulsory_studies,
  } = scholarshipData;

  const { educationalFollowups, reasons } = scholarshipMetadata;

  const handleSelectChange = (target: any) => {
    setData(target.name, target.value);
  };

  const { handleSubmit, control, register, errors, setError, setValue } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schoolEducationalValoration,
  });

  useValidatorAPI(validationErrors, setError);

  const saveEducationalValorationInfo = () => {
    saveData(scholarshipData);
  };

  useButtonAction(buttonActionSave, handleSubmit(saveEducationalValorationInfo));

  const validatorInfo = {
    data: scholarshipData,
    step: 'educationalValoration' as keyof ScholarshipSteps,
  };

  const renderLastCourseFinishedSection = (): JSX.Element | undefined => {
    if (!isEntity && current_situation === ScholarshipCurrentSituationCodes.NINI) {
      return undefined;
    }
    return (
      <EducationalValorationLastCourseForm
        scholarshipData={scholarshipData}
        readOnly={readOnly}
        schema={schoolEducationalValoration}
        control={control}
        errors={errors}
        setValue={setValue}
        register={register}
      />
    );
  };

  const renderIsRenewal = (): JSX.Element | undefined => {
    if (!isNousCims) {
      return undefined;
    }
    return (
      <CheckboxCompo
        name="is_renewal"
        questionText={tScholarshipKey('¿Es renovación?')}
        defaultValue={is_renewal}
        disabled={readOnly}
      />
    );
  };

  const renderFollowUpType = (): JSX.Element | undefined => {
    if (!isNousCims) {
      return undefined;
    }
    return (
      <SelectController
        control={control}
        errors={errors}
        schema={schoolEducationalValoration}
        size="50"
        name="follow_up_type"
        defaultValue={follow_up_type}
        label={tScholarshipKey('follow-up-type')}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {educationalFollowups.map((element: CodeNamePair) => (
          <MenuItem key={element.code} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderCourseSection = (): JSX.Element => {
    return (
      <FormContainer title={tScholarshipKey('Próximo curso')}>
        <EducationalValorationCourseForm
          scholarshipData={scholarshipData}
          isLastCourse={false}
          readOnly={readOnly}
          control={control}
          errors={errors}
          schema={schoolEducationalValoration}
          setValue={setValue}
        />
        {renderFollowUpType()}
      </FormContainer>
    );
  };

  const renderAcademyReasons = (): JSX.Element => {
    return (
      <>
        <SelectController
          control={control}
          errors={errors}
          schema={schoolEducationalValoration}
          size="50"
          name="scholarship_academy_choose_reason_id"
          defaultValue={scholarship_academy_choose_reason_id}
          label={tScholarshipKey(`${scholarshipType}.scholarship_academy_choose_reason_id`)}
          disabled={readOnly}
          validator={validateFields(scholarshipData, 'scholarship_academy_choose_reason_id', validatorInfo.step)}
          onClick={e => handleSelectChange(e.target)}
        >
          {reasons.map((element: IdNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey(`${scholarshipType}.other_reasons`)}
          </InputLabel>
          <TextInputController
            control={control}
            errors={errors}
            schema={schoolEducationalValoration}
            className={styles.size_100}
            size="50"
            disabled={readOnly}
            name="other_reasons"
            defaultValue={other_reasons}
            rows="4"
            rowsMax="10"
            multiline
            validator={validateFields(scholarshipData, 'other_reasons', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('other_reasons', e.target.value)}
          />
        </div>
      </>
    );
  };

  const renderAcademySection = (): JSX.Element => {
    return (
      <FormContainer title="Centro">
        <StudyCenterSelector
          readOnly={readOnly}
          scholarshipType={scholarshipType as ScholarshipType}
          provinces={provinces}
          educational_type_code={educational_type_code}
          academy_code={requested_academy_code}
          academy_name={requested_academy_name}
          setAcademyListItem={(selectedAcademyListItem: AcademySelectItem | null) => {
            const code = selectedAcademyListItem ? selectedAcademyListItem.code : null;
            const name = selectedAcademyListItem ? selectedAcademyListItem.name : null;
            setValue('requested_academy_code', code);
            setData('requested_academy_code', code);
            setValue('requested_academy_name', name);
            setData('requested_academy_name', name);
          }}
        />
        {!isEntity ? renderAcademyReasons() : undefined}
      </FormContainer>
    );
  };

  const entityQuestions = (): JSX.Element | undefined => {
    if (!isEntity) {
      return undefined;
    }

    const vocationalOptions = [
      { value: '0', label: 'none', hiddenT: true, hidden: true },
      { value: '1', label: tScholarshipKey('Taller vocacional') },
    ];

    const individualOptions = [
      { value: '0', label: 'none', hiddenT: true, hidden: true },
      { value: '1', label: tScholarshipKey('Coaching Individual') },
    ];

    return (
      <>
        <RadioButtonGroup
          name="motivational_program"
          disabled={readOnly}
          questionText={tScholarshipKey('participa-programa-vocacional')}
          options={yesNoOptions}
          register={register}
          value={motivational_program ? motivational_program.toString() : '0'}
          validator={validateFields(scholarshipData, 'motivational_program', validatorInfo.step)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData('motivational_program', e.target.value === '1' ? 1 : 0);
          }}
        />
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey('realiza-taller-vocacional')}
          </InputLabel>

          <div className={styles.radioButtonsGroup}>
            <RadioButtonGroup
              name="vocational_workshop"
              disabled={readOnly}
              questionText=""
              options={vocationalOptions}
              value={vocational_workshop?.toString() || '0'}
              register={register}
              validator={validateFields(scholarshipData, 'vocational_workshop', validatorInfo.step)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('vocational_workshop', e.target.value === '1' ? 1 : 0);
              }}
            />
            <div style={{ minWidth: '160px' }}>
              <RadioButtonGroup
                name="individual_coaching"
                disabled={readOnly}
                questionText=""
                options={individualOptions}
                value={individual_coaching?.toString() || '0'}
                register={register}
                validator={validateFields(scholarshipData, 'individual_coaching', validatorInfo.step)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('individual_coaching', e.target.value === '1' ? 1 : 0);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.compoundItem}>
          <RadioButtonGroup
            name="mentoring_program"
            disabled={readOnly}
            questionText={tScholarshipKey(`${scholarshipType}.mentoring_program`)}
            options={yesNoOptions}
            register={register}
            value={mentoring_program ? mentoring_program.toString() : '0'}
            validator={validateFields(scholarshipData, 'mentoring_program', validatorInfo.step)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setData('mentoring_program', e.target.value === '1' ? 1 : 0);
            }}
          />
        </div>
      </>
    );
  };

  const renderQuestionsSection = (): JSX.Element => {
    const parentsWithPostCompulsoryStudiesOptions: RadioOptions[] = [
      { value: 'No', label: tKey('No') },
      { value: 'Sí', label: tKey('Sí') },
      { value: 'No lo sé', label: tScholarshipKey(`${scholarshipType}.dont_know`) },
      { value: 'No tiene familia', label: tScholarshipKey(`${scholarshipType}.no_parents`) },
    ];

    return (
      <FormContainer title="">
        {entityQuestions()}
        <RadioButtonGroup
          name="parents_with_post_compulsory_studies"
          disabled={readOnly}
          questionText={tScholarshipKey(`${scholarshipType}.parents_with_post_compulsory_studies`)}
          options={parentsWithPostCompulsoryStudiesOptions}
          register={register}
          value={parents_with_post_compulsory_studies}
          validator={validateFields(scholarshipData, 'parents_with_post_compulsory_studies', validatorInfo.step)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData('parents_with_post_compulsory_studies', e.target.value);
          }}
        />
      </FormContainer>
    );
  };
  const renderDocumentsSection = (): JSX.Element | undefined => {
    if (documentation.length > 0) {
      const actions = ['checked', 'upload', 'download', 'view', 'remove'];
      return (
        <Fragment>
          <FncAddDocuments
            title={tScholarshipKey('Documentos')}
            documents={documentation}
            getFile={getFile}
            actions={actions}
            disabled={readOnly}
            upload={uploadDocument}
            genericId={id}
            download={downloadDocument}
            remove={deleteDocument}
            infoMessage={tScholarshipKey('adjuntar-expediente-académico')}
            required
            addDoc={addDoc}
            multidocument
          />
          {!isEntity ? (
            <div className={styles.format_file}>
              <p className={styles.parraf}>{tScholarshipKey('parrafo')}</p>
            </div>
          ) : (
            undefined
          )}
        </Fragment>
      );
    }
  };

  return (
    <form onSubmit={e => e.preventDefault()} className={styles.form}>
      <Title>{tScholarshipKey('SOLICITUD DE BECA - VALORACIÓN EDUCATIVA')}</Title>
      {renderLastCourseFinishedSection()}
      {renderIsRenewal()}
      {renderCourseSection()}
      {renderAcademySection()}
      {renderQuestionsSection()}
      {renderDocumentsSection()}
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  documentation: selectGetScholarshipDocumentationPerCode(state, ['EXP', 'OTR']),
  provinces: selectGenericMetadata(state).provinces,
  validationErrors: selectScholarshipValidationErrors(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number | null): void =>
    dispatch(scholarshipSetEducationalInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EducationalValorationForm);
