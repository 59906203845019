import { ButtonProps, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { FocusEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAcademiesFncFromApi } from '../../api/Academies/academies';
import { APIValidationError } from '../../api/api';
import ComboSearch from '../../components/ComboSearch/ComboSearch';
import FormContainer from '../../components/Forms/FormContainer';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../components/Layout/LayoutForm';
import ActionsMenu from '../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../components/Loading/Loading';
import AvatarImagev2 from '../../components/MenuImageRound/MenuImageRound';
import MessageInfo from '../../components/MessageInfo/MessageInfo';
import TransitionModal from '../../components/Modal/TransitionModal';
import SelectController from '../../components/Select/SelectController';
import Title from '../../components/Title/Title';
import useValidatorAPI from '../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../helpers/translate';
import { setUserProfileAvatar } from '../../redux/auth/actions';
import { selectUserAvatar } from '../../redux/auth/selectors';
import { IdCodeNamePair, UserStates, docTypesArray } from '../../redux/common/definitions';
import { GenericMetadata } from '../../redux/metadata/definitions';
import { AppState } from '../../redux/root-reducer';
import { selectGetScholarshipMetadata } from '../../redux/scholarship/selectors';
import { getProfile, updateProfile } from '../../redux/user/actions';
import { UpdateUserFormFields, UserData } from '../../redux/user/definitions';
import { selectIsLoading, selectProfile, selectUserValidationErrors } from '../../redux/user/selectors';
import RoleCode from '../../types/Roles/roleCode';
import { EditUserSchema } from '../../validations/formSchema';
import { validateIdentificationCard } from '../../validations/validatorUser';
import AccessDataForm from './FormLayouts/AccessDataForm';
import ChangePasswordForm from './FormLayouts/ChangePasswordForm';
import styles from './users.module.scss';

interface RegisterDataProps {
  loading: boolean;
  profileData: UserData;
  genericMetadata: GenericMetadata;
  avatar: string | null;
  validationErrors: APIValidationError | null;
  getProfileData: () => void;
  setUserProfileAvatar: (image: string) => void;
  updateProfileUser: (data: UpdateUserFormFields) => void;
  getGenericMetadata: () => void;
}

const RegisterData: React.FC<RegisterDataProps> = ({
  profileData,
  loading,
  avatar,
  validationErrors,
  getProfileData,
  setUserProfileAvatar,
  updateProfileUser,
}) => {
  const history = useHistory();
  const [academies, setAcademies] = useState<IdCodeNamePair[]>([]);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const roleCode = new RoleCode(profileData.role_code);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  useEffect(() => {
    if (roleCode && roleCode.isJovenInstituto() && academies.length === 0) {
      (async function fetchData() {
        const academies = await getAcademiesFncFromApi();
        setAcademies(academies);
      })();
    }
  }, [roleCode, academies]);

  useEffect(() => {
    if (roleCode && roleCode.isJovenInstituto() && profileData.state === UserStates.CREATED) {
      history.push('/mis-becas');
    }
  }, [roleCode, profileData.state, history]);

  const { errors, triggerValidation, getValues, setError, control, clearError } = useForm<UpdateUserFormFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EditUserSchema,
  });

  useValidatorAPI(validationErrors, setError);

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('ACTUALIZAR DATOS'),
      onClick: () => {
        triggerValidation().then(ok => {
          if (ok) {
            if (passwordChanged || roleCode.isJovenInstituto()) {
              updateProfileUser(getValues());
            } else {
              setShowModal(true);
            }
          }
        });
      },
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
    },
  ];
  const { document_type, document_number, name, surname, surname2 } = profileData;

  const renderAcademies = (): JSX.Element | undefined => {
    if (roleCode.isJovenInstituto() && academies.length > 0) {
      return (
        <ComboSearch
          options={academies}
          control={control}
          label={tFormKey('Instituto')}
          optionLabel="name"
          fieldToSend="code"
          error={errors.academy_code}
          validator={true}
          name="academy_code"
        />
      );
    }
    return undefined;
  };

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} goBack={false} />}
      leftSubmenu={<AvatarImagev2 imageLink={avatar} setImage={setUserProfileAvatar} />}
    >
      <React.Fragment>
        <Title>{tKey('Registrar datos')}</Title>
        <MessageInfo>
          {tKey(
            'Registra tus datos y no te olvides de tu fotografía. Recuerda grabar en el icono de la derecha: Actualizar datos.',
          )}
        </MessageInfo>

        {loading && <Loading big />}
        {!loading && (
          <React.Fragment>
            <AccessDataForm profileData={profileData} schema={EditUserSchema} control={control} errors={errors} />
            <MessageInfo>{tKey('Si se acaba de registrar, recuerde poner una contraseña nueva')}</MessageInfo>
            <ChangePasswordForm
              textButton={tFormKey('Guardar Contraseña')}
              handleChangePassword={() => setPasswordChanged(true)}
            />
            <FormContainer title={tFormKey('Datos personales')}>
              <SelectController
                size="50"
                schema={EditUserSchema}
                control={control}
                name="document_type"
                errors={errors}
                label={tFormKey('Tipo de Documento')}
                validator
                defaultValue={document_type || 'DNI'}
                onBlur={(_e: FocusEvent<HTMLInputElement>) =>
                  validateIdentificationCard(
                    _e.target.value,
                    document_number || '',
                    'document_number',
                    setError,
                    clearError,
                  )
                }
              >
                {docTypesArray.map(docType => (
                  <MenuItem key={docType} value={docType}>
                    {docType}
                  </MenuItem>
                ))}
              </SelectController>
              <TextInputController
                size="50"
                label={tFormKey('Nº Documento')}
                validator
                defaultValue={document_number}
                name="document_number"
                errors={errors}
                schema={EditUserSchema}
                control={control}
                onBlur={(_e: FocusEvent<HTMLInputElement>) =>
                  validateIdentificationCard(
                    document_type || '',
                    _e.target.value,
                    'document_number',
                    setError,
                    clearError,
                  )
                }
              />
              <TextInputController
                size="50"
                label={tFormKey('Nombre')}
                defaultValue={name}
                validator
                name="name"
                errors={errors}
                schema={EditUserSchema}
                control={control}
              />
              <TextInputController
                control={control}
                size="50"
                label={tFormKey('Primer Apellido')}
                validator
                name="surname"
                defaultValue={surname}
                errors={errors}
                schema={EditUserSchema}
              />
              <TextInputController
                control={control}
                size="50"
                label={tFormKey('Segundo Apellido')}
                name="surname2"
                defaultValue={surname2}
                errors={errors}
                schema={EditUserSchema}
              />
              {renderAcademies()}
              <TextInputController
                control={control}
                size="50"
                label={tFormKey('Teléfono')}
                name="phone"
                errors={errors}
                schema={EditUserSchema}
                validator
              />
              <TextInputController
                control={control}
                size="50"
                label={tFormKey('Población')}
                name="city"
                errors={errors}
                schema={EditUserSchema}
                validator
              />
            </FormContainer>
          </React.Fragment>
        )}
        <TransitionModal
          view={showModal}
          handleClose={() => setShowModal(false)}
          title={tKey('¡Atención!')}
          handleYes={() => setShowModal(false)}
          buttonOk={tKey('Vale')}
          helperMode
        >
          <div className={styles.modalHelper}>
            {tKey('Para poder continuar con el registro, necesita poner una nueva contraseña')}
          </div>
        </TransitionModal>
      </React.Fragment>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  avatar: selectUserAvatar(state),
  profileData: selectProfile(state),
  validationErrors: selectUserValidationErrors(state),
  scholarshipMetadata: selectGetScholarshipMetadata(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setUserProfileAvatar: (image: string): void => dispatch(setUserProfileAvatar(image)),
  getProfileData: (): void => dispatch(getProfile()),
  updateProfileUser: (data: UpdateUserFormFields): void => dispatch(updateProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterData);
