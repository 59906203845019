import { AcademySelectItem } from '../../redux/academy/definitions';
import { APIResponse, APITypedResponse, IdCodeNamePair } from '../../redux/common/definitions';
import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

export const getAcademiesFncFromApi = async (): Promise<IdCodeNamePair[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/academies/fnc_institute/list`, init);
  return data;
};

export const getAcademySelectListFromApi = async (
  province_code: string,
  educational_type_code: string,
): Promise<AcademySelectItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<APITypedResponse<AcademySelectItem[]>>(
    `${baseURL}/academies/list?province_code=${province_code}&educational_type_code=${educational_type_code}`,
    init,
  );
  return data;
};
