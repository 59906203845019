import { ButtonProps, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import moment, { isMoment } from 'moment';
import React, { Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ClipBoard from '../../../components/ClipBoard/ClipBoard';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import RadioButtonGroup from '../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions } from '../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../components/Select/SelectController';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import TimePickerController from '../../../components/TimePicker/TimePickerController';
import Title from '../../../components/Title/Title';
import * as DateConstants from '../../../constants/date';
import { unixToDateAmericanFormat } from '../../../helpers/dateHelper';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { getAreas } from '../../../redux/area/action';
import { selectSubAreasList } from '../../../redux/area/selectors';
import {
  downloadDocumentEvent,
  eventCreationData,
  getEventData,
  removeDocumentEvent,
  updateEventTable,
  uploadDocumentEvent,
} from '../../../redux/event/actions';
import { EventData, EventDataDispatch, UpdateEventFormFields } from '../../../redux/event/definitions';
import { selectEventData, selectLoading } from '../../../redux/event/selectors';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { AreaSubArea } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import { EventBaseSchema } from '../../../validations/formSchema';
import { LabelsError } from '../../Service/CreateService/CreateServiceNew';
import styles from './createEvent.module.scss';
import SubmenuEvent from './SubmenuEvent';
import { CodeNamePair } from '../../../redux/common/definitions';

interface CreateEventProps extends RouteComponentProps<{ id: string }> {
  event: EventData;
  loading: boolean;
  subList: AreaSubArea[];
  disabled?: boolean;
  readOnly: boolean;
  metadata: GenericMetadata;
  getAreas: () => void;
  getEvent: (id: string) => void;
  setData: (key: string, value: string | number) => void;
  updateTableEvent: (data: UpdateEventFormFields, id: number) => void;
  uploadDocument: (documentationId: number, data: File, eventId: number) => void;
  downloadDocument: (fileId: number, name: string, eventId: number, get?: boolean) => void;
  removeDocument: (fileId: number, eventId: number) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar) => void;
}

const CreateEvent: React.FC<CreateEventProps> = ({
  match,
  event,
  loading,
  subList,
  disabled,
  metadata,
  readOnly = false,
  getAreas,
  getEvent,
  setData,
  updateTableEvent,
  uploadDocument,
  downloadDocument,
  removeDocument,
  showSnackbar,
}) => {
  const {
    params: { id },
  } = match;
  const types = [
    { code: 'online', name: 'Online' },
    { code: 'presencial', name: 'Presencial' },
  ];
  const { provinces, academicYears } = metadata;
  useEffect(() => {
    getAreas();
    getEvent(id);
  }, [id, getEvent, getAreas]);

  const { register, errors, handleSubmit, control, getValues, watch, setError, clearError } = useForm<EventData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EventBaseSchema,
  });

  const linkRef = useRef(null);

  const handleSave = (data: UpdateEventFormFields) => {
    if (!Object.keys(errors).length) {
      updateTableEvent(data, +id);
    } else {
      for (const e of Object.keys(errors)) {
        // @ts-ignore
        setError(e, errors[e].types, errors[e].message);
      }
    }
  };

  const handleGetDocument = (fileId: number, name: string, eventId: number) => {
    downloadDocument(fileId, name, eventId, false);
  };

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('ACTUALIZAR DATOS'),
      onClick: handleSubmit(handleSave),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      hidden: disabled || readOnly,
    },
  ];

  const buttonModal = {
    title: tKey('Cancelar Evento'),
    buttonText: tKey('Cancelar Evento'),
    startIcon: <Close />,
    bodyModal: tKey('¿Está seguro de cancelar el evento?'),
    handleYes: () => {
      const data = getValues();
      data.cancelled = 1;
      updateTableEvent(data, +id);
    },
    buttonOk: tKey('Sí'),
    buttonKo: tKey('No'),
    disabled: !!+event.cancelled,
  };

  const compareDates = (name: keyof EventData, pivot: keyof EventData) => {
    const labels: LabelsError<EventData> = {
      start_date: 'Fechas de inicio',
      start_registration_date: 'Fecha inicio inscripciones',
    };
    const _pivot = getValues()[pivot];
    const _name = getValues()[name];
    if (isMoment(_pivot) && isMoment(_name)) {
      if (_name.isBefore(_pivot.startOf('day'))) {
        setError(name, 'date', tKey(`No puede ser anterior a la ${labels[pivot]}`));
      } else {
        clearError(name);
      }
    }
  };

  const validateDates = (name: keyof EventData, pivot?: keyof EventData) => (
    date: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isValid = moment(date.target.value, DateConstants.DATE_FORMAT, true).isValid();
    if (!isValid) {
      setError(name, 'date', tKey('Fecha invalida'));
      return;
    } else {
      clearError(name);
    }
    if (pivot) {
      compareDates(name, pivot);
    }
  };

  return (
    <LayoutForm
      leftSubmenu={<SubmenuEvent event={event} current_section="datos_basicos" submenu="Datos básicos" />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} isModal={buttonModal} />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <Fragment>
          <Title>{tKey('Datos del evento')}</Title>
          <FormContainer title={tKey('Datos generales')}>
            <TextInputController
              size="50"
              label={tFormKey('Identificador del evento')}
              name="id"
              defaultValue={event.event_id}
              control={control}
              disabled
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Nombre del evento')}
              name="title"
              defaultValue={event.title}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
            />
            <div className={styles.container__subcontainers}>
              <KeyboardDatePickerController
                label={tFormKey('Fecha de alta')}
                name="created_at"
                defaultValue={event.created_at}
                control={control}
                schema={EventBaseSchema}
                errors={errors}
                disabled
                size="50"
                onBlur={validateDates('created_at')}
              />
              <KeyboardDatePickerController
                label={tFormKey('Fecha de inicio')}
                name="start_date"
                control={control}
                defaultValue={event.start_date as number}
                schema={EventBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('start_date')}
              />
            </div>
            <div className={styles.container__subcontainers}>
              <KeyboardDatePickerController
                label={tFormKey('Fecha de fin')}
                name="end_date"
                minDate={watch('start_date') || unixToDateAmericanFormat((event.start_date as number) || 0)}
                minDateMessage={tFormKey('No puede ser anterior a la Fecha de inicio')}
                defaultValue={event.end_date}
                control={control}
                schema={EventBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('end_date', 'start_date')}
              />
              <TimePickerController
                label={tFormKey('Hora')}
                name="time"
                defaultValue={event.time}
                control={control}
                schema={EventBaseSchema}
                errors={errors}
                size="50"
              />
            </div>
            <TextInputController
              size="50"
              label={tFormKey('Dirección')}
              name="address"
              defaultValue={event.address}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Población')}
              name="city"
              defaultValue={event.city}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
            />
            <SelectController
              size="50"
              key="province"
              name="province"
              errors={errors}
              schema={EventBaseSchema}
              defaultValue={event.province || provinces[0].name}
              label={tFormKey('Provincia')}
              disabled={disabled}
              control={control}
            >
              {provinces.map((provinces: CodeNamePair) => (
                <MenuItem key={provinces.code} value={provinces.name}>
                  {provinces.name}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              size="50"
              label={tFormKey('eve.registered_number')}
              name="registered_number"
              defaultValue={event.registered_number || '0'}
              control={control}
              disabled
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('eve.subscribed_number')}
              name="subscribed_number"
              defaultValue={event.subscribed_number || '0'}
              control={control}
              disabled
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('eve.unidentified')}
              name="unidentified"
              defaultValue={event.unidentified || '0'}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="20"
              label={tFormKey('eve.total_assistants')}
              name="total_assistants"
              validator={false}
              defaultValue={event.total_assistants || '0'}
              control={control}
              disabled
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="20"
              label={tFormKey('eve.assistant_number')}
              name="assistant_number"
              validator={false}
              defaultValue={event.assistant_number || '0'}
              control={control}
              disabled
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="50"
              inputRef={linkRef}
              label={tFormKey('Link al formulario de TypeForm')}
              name="registration_link"
              defaultValue={event.registration_link}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
              InputProps={{
                endAdornment: (
                  <ClipBoard
                    disabled={!watch('registration_link', event.registration_link)}
                    value={watch('registration_link', event.registration_link || '')}
                    showSnackbar={showSnackbar}
                  />
                ),
              }}
            />
            <TextInputController
              size="50"
              inputRef={linkRef}
              label={tFormKey('Link de acceso')}
              name="registration_link"
              defaultValue={`http://${window.location.host}/eventos/${id}/login`}
              control={control}
              disabled
              errors={errors}
              schema={EventBaseSchema}
              InputProps={{
                endAdornment: (
                  <ClipBoard value={`http://${window.location.host}/eventos/${id}/login`} showSnackbar={showSnackbar} />
                ),
              }}
            />
            <TextInputController
              size="50"
              label={tFormKey('Link de conexión')}
              name="connection_link"
              defaultValue={event.connection_link}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
              InputProps={{
                endAdornment: (
                  <ClipBoard
                    disabled={!watch('connection_link', event.connection_link)}
                    value={watch('connection_link', event.connection_link || '')}
                    showSnackbar={showSnackbar}
                  />
                ),
              }}
            />
            <TextInputController
              size="50"
              label={tFormKey('Nombre del responsable')}
              name="main_contact"
              defaultValue={event.main_contact}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Correo del responsable')}
              name="main_contact_email"
              defaultValue={event.main_contact_email}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Teléfono del responsable')}
              name="main_contact_phone"
              defaultValue={event.main_contact_phone}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={EventBaseSchema}
            />
            <SelectController
              size="50"
              key="subarea_id"
              name="subarea_id"
              errors={errors}
              schema={EventBaseSchema}
              defaultValue={event.subarea_id}
              label={tFormKey('Área-Subárea')}
              disabled={disabled}
              control={control}
            >
              {subList.map((element: { id: number; name: string }) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              size="50"
              label={tFormKey('Año académico')}
              name="academic_year"
              errors={errors}
              schema={EventBaseSchema}
              defaultValue={event.academic_year || academicYears[0]}
              disabled={disabled}
              control={control}
            >
              {academicYears.map(years => (
                <MenuItem key={years} value={years}>
                  {years}
                </MenuItem>
              ))}
            </SelectController>

            <SelectController
              size="50"
              key="type"
              name="type"
              errors={errors}
              schema={EventBaseSchema}
              defaultValue={event.type || types[0].code}
              label={tFormKey('Tipo de evento')}
              disabled={disabled}
              control={control}
            >
              {types.map(({ code, name }) => (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              ))}
            </SelectController>
            <div className={styles.container__subcontainers}>
              <TextInputController
                size="50"
                label={tFormKey('Aforo máximo')}
                name="max_capacity"
                defaultValue={event.max_capacity || '0'}
                control={control}
                disabled={disabled}
                errors={errors}
                schema={EventBaseSchema}
              />

              <KeyboardDatePickerController
                label={tFormKey('Fecha de cierre')}
                name="closing_date"
                control={control}
                defaultValue={event.closing_date}
                schema={EventBaseSchema}
                errors={errors}
                size="50"
              />
            </div>
            <div className={styles.container__subcontainers}>
              <KeyboardDatePickerController
                label={tFormKey('Fecha inicio inscripciones')}
                name="start_registration_date"
                defaultValue={event.start_registration_date}
                control={control}
                schema={EventBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('start_registration_date')}
              />

              <KeyboardDatePickerController
                label={tFormKey('Fecha fin inscripciones')}
                name="end_registration_date"
                minDate={
                  watch('start_registration_date') || unixToDateAmericanFormat(event.start_registration_date || 0)
                }
                minDateMessage={tErrorKey('eve.end_date_validation')}
                defaultValue={event.end_registration_date}
                control={control}
                schema={EventBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('end_registration_date', 'start_registration_date')}
              />
            </div>
            <div className={styles.container__radiobuttons}>
              <RadioButtonGroup
                name="reminder"
                disabled={disabled}
                questionText={tFormKey('¿Enviar recordatorio?')}
                options={yesNoOptions}
                value={event.reminder?.toString() || '1'}
                register={register}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('reminder', e.target.value);
                }}
              />
              <RadioButtonGroup
                name="cancelled"
                disabled
                questionText={tFormKey('Evento cancelado')}
                options={yesNoOptions}
                value={event.cancelled?.toString() || '0'}
                register={register}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('cancelled', e.target.value);
                }}
              />
            </div>
            <FncAddDocuments
              documents={event.documents}
              getFile={handleGetDocument}
              actions={['checked', 'upload', 'download', 'view', 'remove']}
              filesOk={['png', 'jpg']}
              disabled={disabled}
              upload={uploadDocument}
              genericId={+id}
              download={downloadDocument}
              remove={removeDocument}
              originalName
            />
          </FormContainer>
        </Fragment>
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  event: selectEventData(state),
  loading: selectLoading(state),
  subList: selectSubAreasList(state),
  metadata: selectGenericMetadata(state),
});

const mapDispatchToProps = (dispatch: EventDataDispatch | any) => ({
  getAreas: (): void => dispatch(getAreas()),
  setData: (key: string, value: string | number): void => dispatch(eventCreationData(key, value)),
  updateTableEvent: (data: UpdateEventFormFields, id: number): void => dispatch(updateEventTable(data, id)),
  getEvent: (id: string) => dispatch(getEventData(id)),
  uploadDocument: (documentationId: number, data: File, eventId: number): void =>
    dispatch(uploadDocumentEvent(documentationId, data, eventId)),
  downloadDocument: (fileId: number, name: string, eventId: number, get?: boolean): void =>
    dispatch(downloadDocumentEvent(fileId, name, eventId, get)),
  removeDocument: (fileId: number, eventId: number): void => dispatch(removeDocumentEvent(fileId, eventId)),
  showSnackbar: (message: string, severity: SeveritySnackbar): void => dispatch(showSnackbar(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
