/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { getAcademySelectListFromApi } from '../../../../api/Academies/academies';
import ComboSearchValue from '../../../../components/ComboSearch/ComboSearchValue';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { AcademySelectItem } from '../../../../redux/academy/definitions';
import { CodeNamePair } from '../../../../redux/common/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { ScholarshipType } from '../../../../redux/scholarship/definitions';
import { studyCenterEducationalValoration } from '../../../../validations/scholarshipSchema';

interface StudyCenterSelectorProps {
  educational_type_code: string | null;
  academy_code: string | null;
  academy_name: string | null;
  scholarshipType: ScholarshipType;
  readOnly: boolean;
  provinces: CodeNamePair[];
  setAcademyListItem: (selectedAcademyListItem: AcademySelectItem | null) => void;
}

const StudyCenterSelector: React.FC<StudyCenterSelectorProps> = ({
  educational_type_code,
  academy_code,
  academy_name,
  scholarshipType,
  readOnly,
  provinces,
  setAcademyListItem,
}) => {
  const [academySelectList, setAcademySelectList] = useState<AcademySelectItem[]>([]);
  const [provinceCode, setProvinceCode] = useState<string | null>(academy_code ? academy_code.slice(0, 2) : null);
  const { control, errors, setValue } = useForm<{
    province_code: string | undefined;
    academy_name: string | undefined;
    academy_code: string | undefined;
  }>({
    submitFocusError: true,
    validationSchema: studyCenterEducationalValoration,
  });

  useEffect(() => {
    if (academy_code) {
      setValue('code', academy_code);
      setValue('name', academy_name);
    }
    if (provinceCode) {
      setValue('province_code', provinceCode);
    }
  }, [academy_code, academy_name, provinceCode, setValue]);

  useEffect(() => {
    if (provinceCode && educational_type_code) {
      const getAcademySelectList = async () => {
        try {
          const data: AcademySelectItem[] = await getAcademySelectListFromApi(provinceCode, educational_type_code);
          setAcademySelectList(data);
        } catch (err) {
          setAcademySelectList([] as AcademySelectItem[]);
        }
      };

      getAcademySelectList();
    }
  }, [educational_type_code, provinceCode]);

  const renderProvinceAcademy = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        errors={errors}
        schema={studyCenterEducationalValoration}
        size="50"
        name="province_code"
        label={tScholarshipKey('institute_province')}
        disabled={readOnly}
        defaultValue={provinceCode}
        onClick={(e: any) => {
          setProvinceCode(e.target.value);
        }}
      >
        {provinces.map((element: CodeNamePair) => (
          <MenuItem key={element.code} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderComboSearch = (): JSX.Element => {
    return (
      <ComboSearchValue
        options={academySelectList}
        control={control}
        label={tScholarshipKey(`${scholarshipType}.scholarship_academy`)}
        optionLabel="name"
        fieldToSend="code"
        setValue={setValue}
        value={academy_code}
        onChange={(data: AcademySelectItem) => {
          setAcademyListItem(data);
        }}
        disabled={readOnly}
        error={errors.academy_code}
        name="academy_code"
        clipBoard
      />
    );
  };

  return (
    <>
      {renderProvinceAcademy()}
      {renderComboSearch()}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  provinces: selectGenericMetadata(state).provinces,
});

export default connect(mapStateToProps, undefined)(StudyCenterSelector);
