/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { APIValidationError } from '../../api/api';
import assertUnreachable from '../../helpers/assertUnreachable';
import { getDocs } from '../../helpers/getDocs';
import { DocumentationItem } from '../common/definitions';
import { EntityDataConsts } from './action_types';
import {
  EntityContactData,
  EntityContract,
  EntityDataAction,
  EntityDataState,
  EntityIbanData,
  initialState,
} from './definitions';

const entityReducer = (state = initialState, action: EntityDataAction): EntityDataState => {
  switch (action.type) {
    case EntityDataConsts.ENTITY_GET_PRE_CREATED_USER:
    case EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID:
    case EntityDataConsts.ENTITY_GET_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EntityDataConsts.CHANGE_STATE_ENTITY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EntityDataConsts.ENTITY_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        validationErrors: action.validationErrors,
      };
    case EntityDataConsts.UPDATE_ENTITY_CONTACT:
    case EntityDataConsts.GET_ENTITY_ROLES:
    case EntityDataConsts.GET_ENTITY_ONE_CONTACT:
    case EntityDataConsts.GET_ENTITY_ONE_IBAN:
      return {
        ...state,
        loadingModal: true,
      };
    case EntityDataConsts.UPDATE_ENTITY_IBAN:
    case EntityDataConsts.ADD_ENTITY_IBAN:
      return {
        ...state,
        loadingModal: true,
        loadingIbanList: true,
      };
    case EntityDataConsts.ADD_ENTITY_CONTACT:
      return {
        ...state,
        loadingContactList: true,
      };
    case EntityDataConsts.ENTITY_GET_LIST:
      return {
        ...state,
        loading: true,
      };
    case EntityDataConsts.GET_ENTITY_IBAN:
    case EntityDataConsts.REMOVE_ENTITY_IBAN:
      return {
        ...state,
        loadingIbanList: true,
      };
    case EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT:
    case EntityDataConsts.REMOVE_ENTITY_CONTACT:
    case EntityDataConsts.GET_ENTITY_CONTACTS:
    case EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT:
    case EntityDataConsts.UPLOAD_ENTITY_DOCUMENT:
    case EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT:
    case EntityDataConsts.REMOVE_ENTITY_DOCUMENT:
    case EntityDataConsts.PREENTITY_REMOVE_DOCUMENT:
    case EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT:
    case EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT:
    case EntityDataConsts.GET_ENTITY_DOCUMENT:
    case EntityDataConsts.ENTITY_GET_DOCUMENTS:
    case EntityDataConsts.PREENTITY_GET_DOCUMENT:
    case EntityDataConsts.CHANGE_STATE_ENTITY_OK:
      return { ...state };
    case EntityDataConsts.ENTITY_GET_DOCUMENTS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        documentation: action.documentation,
      };
    case EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT:
    case EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT:
      return {
        ...state,
        loadingModal: true,
      };
    case EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT_OK:
      return {
        ...state,
        loadingModal: false,
        ibanData: {
          ...state.ibanData,
          file_id: action.fileData.id,
          filename: action.fileData.original_name,
        },
      };
    case EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT_OK:
      return {
        ...state,
        loadingModal: false,
        ibanData: {
          ...state.ibanData,
          file_id: null,
          filename: null,
        },
      };

    case EntityDataConsts.GET_ENTITY_DOCUMENT_OK:
    case EntityDataConsts.PREENTITY_GET_DOCUMENT_OK:
      const { dataDoc } = action;
      getDocs(dataDoc, false, action.name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case EntityDataConsts.GET_ENTITY_ONE_CONTACT_OK:
      return {
        ...state,
        error: null,
        contactData: action.contactData,
        loadingModal: false,
      };
    case EntityDataConsts.GET_ENTITY_ONE_IBAN_OK:
      return {
        ...state,
        error: null,
        ibanData: action.ibanData,
        loadingModal: false,
      };
    case EntityDataConsts.UPDATE_ENTITY_CONTACT_OK:
      const { contactData } = action;
      return {
        ...state,
        entityContactsList: state.entityContactsList.map((contact: EntityContactData) =>
          contact.id === contactData.id ? contactData : contact,
        ),
        error: null,
        loadingModal: false,
      };
    case EntityDataConsts.UPDATE_ENTITY_IBAN_OK:
      const { ibanData } = action;
      const result = state.ibanList.map((iban: EntityIbanData) => (iban.id === ibanData.id ? ibanData : iban));
      return {
        ...state,
        ibanList: result,
        error: null,
        loadingModal: false,
        validationErrors: null,
        loadingIbanList: false,
      };
    case EntityDataConsts.RESET_DOCUMENTS_ENTITY:
      return {
        ...state,
        documentsArr: action.docToUpload ? action.docToUpload : [],
      };
    case EntityDataConsts.UPLOAD_ENTITY_DOCUMENT_OK:
    case EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT_OK:
    case EntityDataConsts.REMOVE_ENTITY_DOCUMENT_OK:
    case EntityDataConsts.PREENTITY_REMOVE_DOCUMENT_OK:
      const { documents } = action;
      const index = documents.findIndex(document => document.code === 'L49');
      if (!!state.entityOneData.joined_49_2020 !== !!documents[index]?.mandatory && index !== -1) {
        documents[index].mandatory = !!state.entityOneData.joined_49_2020;
      }
      return {
        ...state,
        loading: false,
        entityOneData: {
          ...state.entityOneData,
        },
        documentation: documents,
      };

    case EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT_OK:
    case EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT_OK:
    case EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT_OK:
      const { data, name } = action;
      getDocs(data, true, name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case EntityDataConsts.ENTITY_GET_LIST_OK:
      const { entityList } = action;
      return {
        ...state,
        loading: false,
        entityList,
      };
    case EntityDataConsts.GET_ENTITY_ROLES_OK:
      const { roleList } = action;
      return {
        ...state,
        loadingModal: false,
        roleList,
      };
    case EntityDataConsts.ADD_ENTITY_CONTACT_OK:
      state.entityContactsList.push(action.contactData);
      return {
        ...state,
        loadingContactList: false,
      };
    case EntityDataConsts.ADD_ENTITY_IBAN_OK:
      return {
        ...state,
        ibanList: [...state.ibanList, action.ibanData],
        loadingIbanList: false,
        loadingModal: false,
        validationErrors: null,
      };
    case EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT_OK:
      getDocs(action.data, true, action.name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case EntityDataConsts.ENTITY_UPDATE:
      return {
        ...state,
        loadingModal: false,
        loading: false,
        error: null,
      };
    case EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT_KO:
    case EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT_KO:
    case EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT_KO:
      return {
        ...state,
        loadingModal: false,
        error: action.type,
      };
    case EntityDataConsts.UPDATE_ENTITY_IBAN_KO:
    case EntityDataConsts.ADD_ENTITY_IBAN_KO:
      return {
        ...state,
        loadingIbanList: false,
        loadingModal: false,
        error: action.error,
      };
    case EntityDataConsts.ADD_ENTITY_CONTACT_KO:
      return {
        ...state,
        loadingContactList: false,
        error: action.error,
      };
    case EntityDataConsts.REMOVE_ENTITY_IBAN_KO:
    case EntityDataConsts.GET_ENTITY_IBAN_KO:
      return {
        ...state,
        loadingIbanList: false,
        error: action.error,
      };
    case EntityDataConsts.REMOVE_ENTITY_CONTACT_KO:
    case EntityDataConsts.GET_ENTITY_CONTACTS_KO:
    case EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT_KO:
    case EntityDataConsts.ENTITY_GET_DOCUMENTS_KO:
    case EntityDataConsts.GET_ENTITY_DOCUMENT_KO:
    case EntityDataConsts.PREENTITY_GET_DOCUMENT_KO:
    case EntityDataConsts.UPLOAD_ENTITY_DOCUMENT_KO:
    case EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT_KO:
    case EntityDataConsts.REMOVE_ENTITY_DOCUMENT_KO:
    case EntityDataConsts.PREENTITY_REMOVE_DOCUMENT_KO:
    case EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT_KO:
    case EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT_KO:
    case EntityDataConsts.ENTITY_GET_LIST_KO:
    case EntityDataConsts.ENTITY_FNC_SEND_KO:
    case EntityDataConsts.PRE_ENTITY_SEND_KO:
    case EntityDataConsts.ENTITY_UPDATE_KO:
    case EntityDataConsts.CHANGE_STATE_ENTITY_KO:
    case EntityDataConsts.ENTITY_GET_ONE_KO:
    case EntityDataConsts.ENTITY_DELETE_ONE_KO:
    case EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_KO:
    case EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID_KO:
    case EntityDataConsts.PREENTITY_SET_AVATAR_KO:
    case EntityDataConsts.ENTITY_SET_AVATAR_KO:
    case EntityDataConsts.ENTITY_GET_AVATAR_KO:
    case EntityDataConsts.PREENTITY_GET_AVATAR_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EntityDataConsts.UPDATE_ENTITY_CONTACT_KO:
    case EntityDataConsts.GET_ENTITY_ROLES_KO:
    case EntityDataConsts.GET_ENTITY_ONE_CONTACT_KO:
    case EntityDataConsts.GET_ENTITY_ONE_IBAN_KO:
      return {
        ...state,
        loadingModal: false,
        error: action.error,
      };
    case EntityDataConsts.CREATE_ENTITY_FNC:
      return {
        ...state,
        error: null,
        validationErrors: null,
      };
    case EntityDataConsts.ENTITY_FNC_SEND:
    case EntityDataConsts.PRE_ENTITY_SEND:
      return {
        ...state,
        error: null,
        creationSended: false,
        validationErrors: null,
      };
    case EntityDataConsts.PRE_ENTITY_UPDATE:
      return { ...state };
    case EntityDataConsts.ENTITY_FNC_SEND_OK:
      return {
        ...state,
        loading: false,
        error: null,
        creationSended: true,
      };
    case EntityDataConsts.GET_ENTITY_CONTACTS_OK:
      return {
        ...state,
        error: null,
        entityContactsList: action.entityContactsList,
      };
    case EntityDataConsts.GET_ENTITY_IBAN_OK:
      return {
        ...state,
        error: null,
        ibanList: action.ibanList,
        loadingIbanList: false,
      };
    case EntityDataConsts.REMOVE_ENTITY_CONTACT_OK:
      return {
        ...state,
        entityContactsList: state.entityContactsList.filter((s: EntityContactData) => s.id !== action.contact_id),
      };
    case EntityDataConsts.REMOVE_ENTITY_IBAN_OK:
      return {
        ...state,
        ibanList: state.ibanList.filter((s: EntityIbanData) => s.id !== action.iban_id),
        loadingIbanList: false,
      };

    case EntityDataConsts.PRE_ENTITY_SEND_OK:
      return {
        ...state,
        creationSended: true,
        entityOneData: action.entityOneData,
      };
    case EntityDataConsts.ENTITY_UPDATE_OK:
      return {
        ...state,
        entityOneData: {
          ...state.entityOneData,
          ...action.entityOneData,
        },
        loading: false,
        error: null,
      };
    case EntityDataConsts.ENTITY_GET_ONE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EntityDataConsts.ENTITY_GET_ONE_OK:
      const { entityOneData, documentation } = action;
      return {
        ...state,
        loading: false,
        error: null,
        entityOneData,
        documentation,
        validationErrors: null,
        //avatar,
      };

    case EntityDataConsts.ENTITY_DELETE_ONE:
      return {
        ...state,
        loading: true,
        error: null,
        responseData: null,
      };
    case EntityDataConsts.ENTITY_DELETE_ONE_OK:
      const { responseData } = action;

      let responseDataResult = null;
      let checkAllSuccess = null;
      const res = responseData.data.find(result => result.success === false);
      if (res && Object.keys(res).length > 0) {
        responseDataResult = responseData;
        checkAllSuccess = false;
      } else {
        responseDataResult = responseData;
        checkAllSuccess = true;
      }

      return {
        ...state,
        loading: false,
        error: null,
        responseData: responseDataResult,
        isAllSuccess: checkAllSuccess,
      };

    case EntityDataConsts.ENTITY_RESET_RESPONSEDATA:
      return {
        ...state,
        loading: false,
        error: null,
        responseData: null,
      };
    case EntityDataConsts.ENTITY_RESET_ALLSUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isAllSuccess: false,
      };
    case EntityDataConsts.ENTITY_SET_PAGESIZE_TABLE_OPTIONS:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case EntityDataConsts.ENTITY_SET_SEARCHTEXT_TABLE_OPTIONS:
      return {
        ...state,
        searchText: action.searchText,
      };
    case EntityDataConsts.ENTITY_SET_COLUMNORDER_TABLE_OPTIONS:
      return {
        ...state,
        columnOrder: action.columnOrder,
      };
    case EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID_OK:
      return {
        ...state,
        entityOneData: { ...action.entityOneData },
        documentation: action.documentation,
        //avatar: action.avatar,
        loading: false,
        error: null,
      };
    case EntityDataConsts.REJECT_ENTITY_DIRECTOR:
    case EntityDataConsts.APPROVE_ENTITY_DIRECTOR:
    case EntityDataConsts.REJECT_INTERNAL_ENTITY:
    case EntityDataConsts.REJECT_EXTERNAL_ENTITY:
    case EntityDataConsts.CONFIRM_ENTITY:
      return {
        ...state,
        loading: true,
        transitionChangeSent: false,
      };
    case EntityDataConsts.REJECT_ENTITY_DIRECTOR_OK:
    case EntityDataConsts.APPROVE_ENTITY_DIRECTOR_OK:
    case EntityDataConsts.REJECT_INTERNAL_ENTITY_OK:
    case EntityDataConsts.REJECT_EXTERNAL_ENTITY_OK:
    case EntityDataConsts.CONFIRM_ENTITY_OK:
      return {
        ...state,
        error: null,
        loading: false,
        transitionChangeSent: true,
      };
    case EntityDataConsts.CLEAR_NAVIGATION:
      return {
        ...state,
        transitionChangeSent: false,
        creationSended: false,
      };
    case EntityDataConsts.ENTITY_GET_USER_OK:
      return {
        ...state,
        entityOneData: { ...action.entityOneData },
        documentation: action.documentation,
        //avatar: action.avatar,
        loading: false,
        error: null,
      };
    case EntityDataConsts.PRE_ENTITY_UPDATE_OK:
      return {
        ...state,
        entityOneData: { ...action.entityOneData },
        loading: false,
        error: null,
      };
    case EntityDataConsts.REJECT_EXTERNAL_ENTITY_KO:
    case EntityDataConsts.REJECT_INTERNAL_ENTITY_KO:
    case EntityDataConsts.CONFIRM_ENTITY_KO:
    case EntityDataConsts.REJECT_ENTITY_DIRECTOR_KO:
    case EntityDataConsts.APPROVE_ENTITY_DIRECTOR_KO:
    case EntityDataConsts.PRE_ENTITY_UPDATE_KO:
    case EntityDataConsts.ENTITY_GET_USER_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    case EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_OK:
      return {
        ...state,
        loading: false,
        creationSended: false,
        entityOneData: { ...action.entitiesData },
        documentation: action.documentation,
        //avatar: action.avatar,
      };

    case EntityDataConsts.ENTITIES_CHANGE:
      return {
        ...state,
        changePage: action.changePage,
      };
    case EntityDataConsts.PREENTITY_GET_AVATAR:
    case EntityDataConsts.ENTITY_GET_AVATAR:
      return {
        ...state,
      };
    case EntityDataConsts.ENTITY_SET_AVATAR:
    case EntityDataConsts.PREENTITY_SET_AVATAR:
      return {
        ...state,
      };
    case EntityDataConsts.PREENTITY_SET_AVATAR_OK:
    case EntityDataConsts.ENTITY_SET_AVATAR_OK:
      return {
        ...state,
        avatar: action.avatar,
        error: null,
      };
    case EntityDataConsts.PREENTITY_GET_AVATAR_OK:
    case EntityDataConsts.ENTITY_GET_AVATAR_OK:
      return {
        ...state,
        avatar: action.avatar,
        error: null,
      };
    case EntityDataConsts.SET_CONTRACT_DATA:
      const { key: c_key, value: c_value } = action;
      return {
        ...state,
        contractData: {
          ...state.contractData,
          [c_key]: c_value,
        },
      };
    case EntityDataConsts.ENTITY_SET_DATA:
      const { key, value } = action;
      let { legal_person, natural_person, academy_code, is_visa_provider } = state.entityOneData;

      switch (key) {
        case 'is_provider':
          if (value === 0) {
            legal_person = 0;
            natural_person = 0;
            is_visa_provider = 0;
          } else {
            legal_person = 1;
            natural_person = 0;
          }
          break;
        case 'legal_person':
          if (value === 1) {
            natural_person = 0;
          }
          break;
        case 'natural_person':
          if (value === 1) {
            legal_person = 0;
          }
          break;
        case 'study_center':
          if (!value) {
            academy_code = null;
          }
          break;
      }

      return {
        ...state,
        entityOneData: {
          ...state.entityOneData,
          legal_person,
          natural_person,
          is_visa_provider,
          academy_code,
          [key]: value,
        },
      };
    case EntityDataConsts.ENTITY_CONTACT_SET_DATA:
      const { co_key, co_value } = action;
      return {
        ...state,
        contactData: {
          ...state.contactData,
          [co_key]: co_value,
        },
      };
    case EntityDataConsts.ENTITY_CHANGE_RESPONSIBLE:
      return {
        ...state,
        loading: true,
      };
    case EntityDataConsts.ENTITY_SET_IBAN_DATA:
      const { s_key, s_value } = action;
      return {
        ...state,
        ibanData: {
          ...state.ibanData,
          [s_key]: s_value,
        },
      };
    case EntityDataConsts.ENTITY_CHANGE_RESPONSIBLE_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EntityDataConsts.MAKE_PROVIDER:
      return {
        ...state,
        sendingSage: true,
        error: null,
      };
    case EntityDataConsts.MAKE_PROVIDER_OK:
      const {
        entity: { provider_code, payable_account, expense_account, supplier_code, supplier_payable_account },
      } = action;
      return {
        ...state,
        entityOneData: {
          ...state.entityOneData,
          provider_code,
          payable_account,
          expense_account,
          supplier_code,
          supplier_payable_account,
        },
        sendingSage: false,
        error: null,
      };
    case EntityDataConsts.MAKE_PROVIDER_KO:
      return {
        ...state,
        loading: false,
        sendingSage: false,
        error: action.error,
      };
    case EntityDataConsts.SET_MANDATORY_DOCUMENT:
      const { code, mandatory } = action;
      const newDocumentation: DocumentationItem[] = state.documentation.map(document => {
        if (document.code === code) document.mandatory = mandatory;
        return document;
      });
      return {
        ...state,
        documentation: newDocumentation,
        entityOneData: {
          ...state.entityOneData,
          joined_49_2020: +mandatory,
          required: {
            ...state.entityOneData.required,
            complete: false,
          },
        },
      };
    case EntityDataConsts.ADD_CONTRACT:
      const { entity_id } = action;
      return {
        ...state,
        contractData: {
          id: 0,
          entity_id,
          contract_number: null,
          start_date: moment().unix(),
          end_date: null,
          file_id: null,
          filename: '',
        },
      };
    case EntityDataConsts.GET_CONTRACT:
      return {
        ...state,
        loadingContract: true,
      };
    case EntityDataConsts.GET_CONTRACT_OK:
      return {
        ...state,
        loadingContract: false,
        contractData: action.contract,
      };
    case EntityDataConsts.GET_CONTRACT_KO:
      return {
        ...state,
        loadingContract: false,
        error: action.error,
      };
    case EntityDataConsts.CREATE_NEW_CONTRACT:
    case EntityDataConsts.EDIT_CONTRACT:
      return {
        ...state,
        loadingContract: false,
      };
    case EntityDataConsts.CREATE_NEW_CONTRACT_OK:
    case EntityDataConsts.EDIT_CONTRACT_OK:
      return {
        ...state,
        loadingContract: false,
        contractModalView: false,
        entityOneData: {
          ...state.entityOneData,
          entity_contracts: action.entity_contracts,
        },
      };
    case EntityDataConsts.CREATE_NEW_CONTRACT_KO:
    case EntityDataConsts.EDIT_CONTRACT_KO:
      return {
        ...state,
        error: action.error,
        loadingContract: false,
      };
    case EntityDataConsts.DELETE_CONTRACT:
      return {
        ...state,
        loadingContract: true,
      };
    case EntityDataConsts.DELETE_CONTRACT_OK:
      const indexF = state.entityOneData.entity_contracts
        .map(x => {
          return x.id;
        })
        .indexOf(action.contractData.id);

      state.entityOneData.entity_contracts.splice(indexF, 1);
      return {
        ...state,
        loadingContract: false,
      };
    case EntityDataConsts.DELETE_CONTRACT_KO:
      return {
        ...state,
        error: action.error,
        loadingContract: false,
      };
    case EntityDataConsts.RESET_CONTRACT:
      return {
        ...state,
        contractData: {} as EntityContract,
        validationErrors: null as APIValidationError | null,
      };
    case EntityDataConsts.REMOVE_CONTRACT_DOCUMENT:
    case EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT:
      return {
        ...state,
      };
    case EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT_OK:
      const { fileData } = action;
      return {
        ...state,
        contractData: {
          ...state.contractData,
          file_id: fileData.id,
          filename: fileData.original_name,
        },
      };
    case EntityDataConsts.REMOVE_CONTRACT_DOCUMENT_OK:
      return {
        ...state,
        contractData: {
          ...state.contractData,
          file_id: null,
          filename: null,
        },
      };
    case EntityDataConsts.REMOVE_CONTRACT_DOCUMENT_KO:
    case EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EntityDataConsts.OPEN_CONTACT_MODAL:
      return {
        ...state,
        contactModalView: true,
      };
    case EntityDataConsts.OPEN_CONTRACT_MODAL:
      return {
        ...state,
        contractModalView: true,
      };
    case EntityDataConsts.CLOSE_CONTACT_MODAL:
      return {
        ...state,
        contactModalView: false,
      };
    case EntityDataConsts.CLOSE_CONTRACT_MODAL:
      return {
        ...state,
        contractModalView: false,
      };
    case EntityDataConsts.DELETE_CONTACT_DATA:
      return {
        ...state,
        contactData: {} as EntityContactData,
      };
    case EntityDataConsts.CLOSE_IBAN_MODAL:
      return {
        ...state,
        ibanModalView: false,
      };
    case EntityDataConsts.OPEN_IBAN_MODAL:
      return {
        ...state,
        ibanModalView: true,
      };
    case EntityDataConsts.DELETE_IBAN_DATA:
      return {
        ...state,
        ibanData: {} as EntityIbanData,
      };
  }

  assertUnreachable(action);
  return state;
};

export default entityReducer;
