import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { tErrorKey } from '../../helpers/translate';
import requiredStyle from '../../helpers/validator.module.scss';
import ClipBoardConnected from '../ClipBoard/ClipBoardConnected';
import styles from './comboSearch.module.scss';

export interface AutocompleteProps {
  options: any[];
  optionLabel: string;
  label: string;
  size?: string;
  control: any;
  fieldToSend: string;
  error?: FieldError | any;
  name: string;
  disabled?: boolean;
  fullWidth?: boolean;
  validator?: boolean;
  value?: string | null;
  setValue?: any;
  clipBoard?: boolean;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
  onSelect?: (e: any) => void;
  onBlur?: (data: any) => void;
  onClipBoard?: (value: any) => string;
}

const ComboSearchValue: React.FC<AutocompleteProps> = ({
  label,
  size = '100',
  options,
  control,
  error,
  disabled,
  optionLabel,
  fieldToSend,
  name,
  fullWidth,
  validator,
  value,
  setValue,
  clipBoard,
  onChange,
  onClick,
  onSelect,
  onBlur,
  onClipBoard,
}) => {
  React.useEffect(() => {
    if (value) {
      const defaultOption = options.find(option => option[optionLabel] === value || option[fieldToSend] === value);
      setValue && setValue(name, defaultOption);
    }
  }, [value, options, setValue, name, optionLabel, fieldToSend]);

  const handleChange = (data: any) => {
    onChange && onChange(data);
    return !!data ? data[fieldToSend] : null;
  };

  const handleOnBlur = (data: any) => {
    onBlur && onBlur(data);
    return !!data ? data[fieldToSend] : null;
  };

  return (
    <div className={fullWidth ? styles.comboContainerFull : styles.comboContainer}>
      <Controller
        name={name}
        control={control}
        defaultValue={value || null}
        onChange={([event, data]) => handleChange(data)}
        onBlur={([event, data]) => handleOnBlur(data)}
        as={
          <Autocomplete
            id="combo-box"
            disabled={disabled}
            options={options}
            getOptionLabel={(option: any) => option[optionLabel]}
            value={
              value ? options.find(option => option[optionLabel] === value || option[fieldToSend] === value) : null
            }
            classes={{
              root: `${styles.autocomplete} ${!!clipBoard ? styles.hasClipBoard : ''}`,
              inputRoot: validator ? requiredStyle.requiredAutocomplete : styles.input,
            }}
            onClick={onClick}
            renderInput={params => (
              <TextField
                {...params}
                style={{ width: `${size}%` }}
                label={label}
                error={!!error}
                helperText={tErrorKey(error?.message as string)}
                variant="outlined"
                onSelect={onSelect}
              />
            )}
          />
        }
      />
      {clipBoard && (
        <div className={styles.clipBoard}>
          <ClipBoardConnected value={value as string} disabled={!value} onClipBoard={onClipBoard} />
        </div>
      )}
    </div>
  );
};

export default ComboSearchValue;
