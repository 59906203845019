import { Action } from 'redux';
import { APIValidationError } from '../../api/api';
import { FileItem, IdCodeNamePair } from '../common/definitions';
import { AcademyDataConsts } from './action_types';

export const initialState = {
  loading: false,
  loadingIbanTab: false,
  loadingModal: false,
  error: null as string | null,
  academyData: {} as AcademyData,
  academyIbans: [] as AcademyIban[],
  academyContactsList: [] as AcademyContactData[],
  contactData: {} as AcademyContactData,
  loadingAcademicContact: false,
  contactModalView: false,
  loadingContactsList: false,
  ibanModalView: false,
  ibanData: {} as AcademyIban,
  validationErrors: null as APIValidationError | null,
};

export interface AcademySelectItem {
  id: number;
  code: string;
  name: string;
  nature: string;
}

export interface AcademyData {
  id: number;
  code: string;
  name: string;
  address: string;
  phone: string;
  fax: string;
  email: string;
  zip_code: string;
  nature: string;
  region: string;
  municipality: string;
  town: string;
  territorial_service: string;
  scholarhip_educational_types: string[];
  educational_types: IdCodeNamePair[];
  enabled: boolean;
}

export interface AcademyContactData {
  academy_id: number;
  id: number;
  staff_role: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  description: string | null;
}

export interface AcademyFormatedData extends AcademyData {
  educational_codes: string[];
}

export interface AcademyIban {
  id: number;
  alias: string;
  iban: string;
  file_id: number | null;
  filename: string | null;
  active: boolean | number;
  academy_id: number;
}

export type AcademyDataItem = {
  id: number;
  code: string;
  name: string;
  address: string;
  phone: string;
  email: string;
  zip_code: string;
  nature: string;
  town: string;
  enabled: boolean;
  educational_types: string[];
};

export type AcademyDataList = {
  current_page: number;
  total_count: number;
  per_page: number;
  academies: AcademyDataItem[];
};

export interface GetAcademyDataAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_DATA;
  id: number;
}

export interface GetAcademyDataOkAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_DATA_OK;
  academyData: AcademyData;
}

export interface GetAcademyDataKoAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_DATA_KO;
  error: string;
}

export interface ImportAcademiesAction extends Action {
  type: AcademyDataConsts.IMPORT_ACADEMIES;
  user_id: number;
}
export interface ImportAcademiesOkAction extends Action {
  type: AcademyDataConsts.IMPORT_ACADEMIES_OK;
}

export interface ImportAcademiesKoAction extends Action {
  type: AcademyDataConsts.IMPORT_ACADEMIES_KO;
  error: string;
}

export interface AcademyContactsListAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_CONTACTS;
  academy_id: number;
}

export interface AcademyContactsListOkAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_CONTACTS_OK;
  academyContactsList: AcademyContactData[];
}

export interface AcademyContactsListKoAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_CONTACTS_KO;
  error: string;
}

export interface AcademyContactAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_CONTACT;
  academy_id: number;
  staff_id: number;
}

export interface AcademyContactOkAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_CONTACT_OK;
  contactData: AcademyContactData;
}

export interface AcademyContactKoAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_CONTACT_KO;
  error: string;
}

export interface CreateAcademyContactAction extends Action {
  type: AcademyDataConsts.ADD_ACADEMY_CONTACT;
  contactData: AcademyContactData;
  academy_id: number;
}

export interface CreateAcademyContactOkAction extends Action {
  type: AcademyDataConsts.ADD_ACADEMY_CONTACT_OK;
  contactData: AcademyContactData;
}

export interface CreateAcademyContactKoAction extends Action {
  type: AcademyDataConsts.ADD_ACADEMY_CONTACT_KO;
  error: string;
}

export interface AcademyUpdateContactAction extends Action {
  type: AcademyDataConsts.UPDATE_ACADEMY_CONTACT;
  contactInfo: any;
  academy_id: number;
}

export interface AcademyUpdateContactOkAction extends Action {
  type: AcademyDataConsts.UPDATE_ACADEMY_CONTACT_OK;
  contactData: AcademyContactData;
}

export interface AcademyUpdateContactKoAction extends Action {
  type: AcademyDataConsts.UPDATE_ACADEMY_CONTACT_KO;
  error: string;
}

export interface RemoveContactAcademyAction extends Action {
  type: AcademyDataConsts.REMOVE_ACADEMY_CONTACT;
  academy_id: number;
  staff_id: number;
}

export interface RemoveContactAcademyOkAction extends Action {
  type: AcademyDataConsts.REMOVE_ACADEMY_CONTACT_OK;
  staff_id: number;
}

export interface RemoveContactAcademyKoAction extends Action {
  type: AcademyDataConsts.REMOVE_ACADEMY_CONTACT_KO;
  error: string;
}
export interface ContactSetInfoDataAction extends Action {
  type: AcademyDataConsts.CONTACT_SET_DATA;
  key_detail: string;
  value_detail: string | number;
}
export interface OpenContactModalAction extends Action {
  type: AcademyDataConsts.OPEN_CONTACT_MODAL;
}
export interface CloseContactModalAction extends Action {
  type: AcademyDataConsts.CLOSE_CONTACT_MODAL;
}
export interface DeleteContactDataAction extends Action {
  type: AcademyDataConsts.DELETE_CONTACT_DATA;
}

export interface ReplaceAcademyContactListAction extends Action {
  type: AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST;
  dataList: AcademyContactData[];
  academy_id: number;
}

export interface ReplaceAcademyContactListOkAction extends Action {
  type: AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST_OK;
  academyContactsList: AcademyContactData[];
}

export interface ReplaceAcademyContactListKoAction extends Action {
  type: AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST_KO;
  error: string;
}
export interface AddAcademyIbanAction extends Action {
  type: AcademyDataConsts.ADD_ACADEMY_IBAN;
  academyId: number;
  academyIban: AcademyIban;
}
export interface AddAcademyIbanOkAction extends Action {
  type: AcademyDataConsts.ADD_ACADEMY_IBAN_OK;
  academyIban: AcademyIban;
}

export interface AddAcademyIbanKoAction extends Action {
  type: AcademyDataConsts.ADD_ACADEMY_IBAN_KO;
  error: string;
}

export interface GetAcademyIbanAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_IBAN;
  academyId: number;
  academyIbanId: number;
}
export interface GetAcademyIbanOkAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_IBAN_OK;
  academyIban: AcademyIban;
}

export interface GetAcademyIbanKoAction extends Action {
  type: AcademyDataConsts.GET_ACADEMY_IBAN_KO;
  error: string;
}
export interface RemoveAcademyIbanAction extends Action {
  type: AcademyDataConsts.REMOVE_ACADEMY_IBAN;
  academyId: number;
  academyIbanId: number;
}
export interface RemoveAcademyIbanOkAction extends Action {
  type: AcademyDataConsts.REMOVE_ACADEMY_IBAN_OK;
  academyIbanId: number;
}

export interface RemoveAcademyIbanKoAction extends Action {
  type: AcademyDataConsts.REMOVE_ACADEMY_IBAN_KO;
  error: string;
}
export interface ListAcademyIbansAction extends Action {
  type: AcademyDataConsts.LIST_ACADEMY_IBANS;
  id: number;
}
export interface GetAcademyIbansOkAction extends Action {
  type: AcademyDataConsts.LIST_ACADEMY_IBANS_OK;
  academyIbans: AcademyIban[];
}

export interface GetAcademyIbansKoAction extends Action {
  type: AcademyDataConsts.LIST_ACADEMY_IBANS_KO;
  error: string;
}

export interface EditAcademyIbanAction extends Action {
  type: AcademyDataConsts.EDIT_ACADEMY_IBAN;
  academyIban: AcademyIban;
  academyId: number;
}
export interface EditAcademyIbanOkAction extends Action {
  type: AcademyDataConsts.EDIT_ACADEMY_IBAN_OK;
  academyIban: AcademyIban;
}

export interface EditAcademyIbanKoAction extends Action {
  type: AcademyDataConsts.EDIT_ACADEMY_IBAN_KO;
  error: string;
}
export interface OpenIbanModalAction extends Action {
  type: AcademyDataConsts.OPEN_IBAN_MODAL;
}
export interface CloseIbanModalAction extends Action {
  type: AcademyDataConsts.CLOSE_IBAN_MODAL;
}

export interface ClearIbanDataAction extends Action {
  type: AcademyDataConsts.CLEAR_IBAN_DATA;
}
export interface SetIbanDataAction extends Action {
  type: AcademyDataConsts.SET_IBAN_DATA;
  key: string;
  value: string | number;
}

export interface SetAcademyIbanAction extends Action {
  type: AcademyDataConsts.SET_ACADEMY_LIST_IBAN;
  id: number;
  key: keyof AcademyIban;
  value: AcademyIban[keyof AcademyIban];
}

export interface UploadIbanDocumentAction extends Action {
  type: AcademyDataConsts.UPLOAD_IBAN_DOCUMENT;
  file: File;
}

export interface UploadIbanDocumentOkAction extends Action {
  type: AcademyDataConsts.UPLOAD_IBAN_DOCUMENT_OK;
  fileData: FileItem;
}

export interface UploadIbanDocumentKoAction extends Action {
  type: AcademyDataConsts.UPLOAD_IBAN_DOCUMENT_KO;
  error: string;
}

export interface RemoveIbanDocumentAction extends Action {
  type: AcademyDataConsts.REMOVE_IBAN_DOCUMENT;
  file_id: number;
}

export interface RemoveIbanDocumentOkAction extends Action {
  type: AcademyDataConsts.REMOVE_IBAN_DOCUMENT_OK;
}

export interface RemoveIbanDocumentKoAction extends Action {
  type: AcademyDataConsts.REMOVE_IBAN_DOCUMENT_KO;
  error: string;
}

export interface DownloadIbanDocumentAction extends Action {
  type: AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT;
  file_id: number;
  filename: string;
}

export interface DownloadIbanDocumentOkAction extends Action {
  type: AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT_OK;
  data: Blob;
  name: string;
}

export interface DownloadIbanDocumentKoAction extends Action {
  type: AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT_KO;
  error: string;
}

//EDIT IBAN IN BULK

export interface EditBulkAcademyIbanAction extends Action {
  type: AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN;
  academiesIbans: AcademyIban[];
}
export interface EditBulkAcademyIbanOkAction extends Action {
  type: AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN_OK;
}

export interface EditBulkAcademyIbanKoAction extends Action {
  type: AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN_KO;
  error: string;
}

export interface AcademyValidationErrorsAction extends Action {
  type: AcademyDataConsts.ACADEMY_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

export type AcademyDataAction =
  | GetAcademyDataAction
  | GetAcademyDataOkAction
  | GetAcademyDataKoAction
  | ImportAcademiesAction
  | ImportAcademiesOkAction
  | ImportAcademiesKoAction
  | AcademyContactsListAction
  | AcademyContactsListOkAction
  | AcademyContactsListKoAction
  | CreateAcademyContactAction
  | CreateAcademyContactOkAction
  | CreateAcademyContactKoAction
  | AcademyUpdateContactAction
  | AcademyUpdateContactOkAction
  | AcademyUpdateContactKoAction
  | RemoveContactAcademyAction
  | RemoveContactAcademyOkAction
  | RemoveContactAcademyKoAction
  | AcademyContactAction
  | AcademyContactOkAction
  | AcademyContactKoAction
  | ContactSetInfoDataAction
  | ReplaceAcademyContactListAction
  | ReplaceAcademyContactListOkAction
  | ReplaceAcademyContactListKoAction
  | ContactSetInfoDataAction
  | OpenContactModalAction
  | CloseContactModalAction
  | DeleteContactDataAction
  | AddAcademyIbanAction
  | AddAcademyIbanOkAction
  | AddAcademyIbanKoAction
  | GetAcademyIbanAction
  | GetAcademyIbanOkAction
  | GetAcademyIbanKoAction
  | ListAcademyIbansAction
  | GetAcademyIbansOkAction
  | GetAcademyIbansKoAction
  | EditAcademyIbanAction
  | EditAcademyIbanOkAction
  | EditAcademyIbanKoAction
  | OpenIbanModalAction
  | CloseIbanModalAction
  | SetAcademyIbanAction
  | ClearIbanDataAction
  | SetIbanDataAction
  | EditBulkAcademyIbanAction
  | EditBulkAcademyIbanOkAction
  | EditBulkAcademyIbanKoAction
  | UploadIbanDocumentAction
  | UploadIbanDocumentOkAction
  | UploadIbanDocumentKoAction
  | RemoveIbanDocumentAction
  | RemoveIbanDocumentOkAction
  | RemoveIbanDocumentKoAction
  | DownloadIbanDocumentAction
  | DownloadIbanDocumentOkAction
  | DownloadIbanDocumentKoAction
  | AcademyValidationErrorsAction
  | RemoveAcademyIbanAction
  | RemoveAcademyIbanOkAction
  | RemoveAcademyIbanKoAction;

export type AcademyDataDispatch = (action: AcademyDataAction) => void;
