export enum AcademyDataConsts {
  GET_ACADEMY_DATA = 'GET:ACADEMY:DATA',
  GET_ACADEMY_DATA_OK = 'GET:ACADEMY:DATA:OK',
  GET_ACADEMY_DATA_KO = 'GET:ACADEMY:DATA:KO',

  IMPORT_ACADEMIES = 'IMPORT:ACADEMIES',
  IMPORT_ACADEMIES_OK = 'IMPORT:ACADEMIES:OK',
  IMPORT_ACADEMIES_KO = 'IMPORT:ACADEMIES:KO',

  GET_ACADEMY_CONTACTS = 'GET:ACADEMY:CONTACTS',
  GET_ACADEMY_CONTACTS_OK = 'GET:ACADEMY:CONTACTS:OK',
  GET_ACADEMY_CONTACTS_KO = 'GET:ACADEMY:CONTACTS:KO',

  GET_ACADEMY_CONTACT = 'GET:ACADEMY:CONTACT',
  GET_ACADEMY_CONTACT_OK = 'GET:ACADEMY:CONTACT:OK',
  GET_ACADEMY_CONTACT_KO = 'GET:ACADEMY:CONTACT:KO',

  ADD_ACADEMY_CONTACT = 'ADD:ACADEMY:CONTACT',
  ADD_ACADEMY_CONTACT_OK = 'ADD:ACADEMY:CONTACT:OK',
  ADD_ACADEMY_CONTACT_KO = 'ADD:ACADEMY:CONTACT:KO',

  REMOVE_ACADEMY_CONTACT = 'REMOVE:ACADEMY:CONTACT',
  REMOVE_ACADEMY_CONTACT_OK = 'REMOVE:ACADEMY:CONTACT:OK',
  REMOVE_ACADEMY_CONTACT_KO = 'REMOVE:ACADEMY:CONTACT:KO',

  UPDATE_ACADEMY_CONTACT = 'UPDATE:ACADEMY:CONTACT',
  UPDATE_ACADEMY_CONTACT_OK = 'UPDATE:ACADEMY:CONTACT:OK',
  UPDATE_ACADEMY_CONTACT_KO = 'UPDATE:ACADEMY:CONTACT:KO',

  CONTACT_SET_DATA = 'CONTACT:SET:DATA',

  REPLACE_ACADEMY_CONTACTS_LIST = 'REPLACE:ACADEMY:CONTACTS:LIST',
  REPLACE_ACADEMY_CONTACTS_LIST_OK = 'REPLACE:ACADEMY:CONTACTS:LIST:OK',
  REPLACE_ACADEMY_CONTACTS_LIST_KO = 'REPLACE:ACADEMY:CONTACTS:LIST:KO',

  OPEN_CONTACT_MODAL = 'OPEN:CONTACT:MODAL',
  CLOSE_CONTACT_MODAL = 'CLOSE:CONTACT:MODAL',
  DELETE_CONTACT_DATA = 'DELETE:CONTACT:DATA',

  ADD_ACADEMY_IBAN = 'ADD:ACADEMY:IBAN',
  ADD_ACADEMY_IBAN_OK = 'ADD:ACADEMY:IBAN:OK',
  ADD_ACADEMY_IBAN_KO = 'ADD:ACADEMY:IBAN:KO',

  GET_ACADEMY_IBAN = 'GET:ACADEMY:IBAN',
  GET_ACADEMY_IBAN_OK = 'GET:ACADEMY:IBAN:OK',
  GET_ACADEMY_IBAN_KO = 'GET:ACADEMY:IBAN:KO',

  LIST_ACADEMY_IBANS = 'LIST:ACADEMY:IBANS',
  LIST_ACADEMY_IBANS_OK = 'LIST:ACADEMY:IBANS:OK',
  LIST_ACADEMY_IBANS_KO = 'LIST:ACADEMY:IBANS:KO',

  EDIT_ACADEMY_IBAN = 'EDIT:ACADEMY:IBAN',
  EDIT_ACADEMY_IBAN_OK = 'EDIT:ACADEMY:IBAN:OK',
  EDIT_ACADEMY_IBAN_KO = 'EDIT:ACADEMY:IBAN:KO',

  REMOVE_ACADEMY_IBAN = 'REMOVE:ACADEMY:IBAN',
  REMOVE_ACADEMY_IBAN_OK = 'REMOVE:ACADEMY:IBAN:OK',
  REMOVE_ACADEMY_IBAN_KO = 'REMOVE:ACADEMY:IBAN:KO',

  EDIT_BULK_ACADEMY_IBAN = 'EDIT:BULK:ACADEMY:IBAN',
  EDIT_BULK_ACADEMY_IBAN_OK = 'EDIT:BULK:ACADEMY:IBAN:OK',
  EDIT_BULK_ACADEMY_IBAN_KO = 'EDIT:BULK:ACADEMY:IBAN:KO',

  DOWNLOAD_DOCUMENT = 'DOWNLOAD:ACADEMY:DOCUMENT',
  DOWNLOAD_DOCUMENT_OK = 'DOWNLOAD:ACADEMY:DOCUMENT:OK',
  DOWNLOAD_DOCUMENT_KO = 'DOWNLOAD:ACADEMY:DOCUMENT:KO',

  OPEN_IBAN_MODAL = 'OPEN:IBAN:MODAL',
  CLOSE_IBAN_MODAL = 'CLOSE:IBAN:MODAL',

  SET_ACADEMY_LIST_IBAN = 'SET:ACADEMY:LIST:IBAN',
  SET_IBAN_DATA = 'SET:IBAN:DATA',
  CLEAR_IBAN_DATA = 'CLEAR:IBAN:DATA',

  UPLOAD_IBAN_DOCUMENT = 'UPLOAD:IBAN:DOCUMENT',
  UPLOAD_IBAN_DOCUMENT_OK = 'UPLOAD:IBAN:DOCUMENT:OK',
  UPLOAD_IBAN_DOCUMENT_KO = 'UPLOAD:IBAN:DOCUMENT:KO',

  REMOVE_IBAN_DOCUMENT = 'REMOVE:IBAN:DOCUMENT',
  REMOVE_IBAN_DOCUMENT_OK = 'REMOVE:IBAN:DOCUMENT:OK',
  REMOVE_IBAN_DOCUMENT_KO = 'REMOVE:IBAN:DOCUMENT:KO',

  DOWNLOAD_IBAN_DOCUMENT = 'DOWNLOAD:IBAN:DOCUMENT',
  DOWNLOAD_IBAN_DOCUMENT_OK = 'DOWNLOAD:IBAN:DOCUMENT:OK',
  DOWNLOAD_IBAN_DOCUMENT_KO = 'DOWNLOAD:IBAN:DOCUMENT:KO',

  ACADEMY_VALIDATION_ERRORS = 'ACADEMY:VALIDATION:ERRORS',
}
