import { IdCodeColor } from '../common/definitions';
import { AppState } from '../root-reducer';
import { AcademyContactData, AcademyData, AcademyFormatedData } from './definitions';
import { AcademyDataState } from './reducers';

const colors = [
  '#fff718',
  '#5696fc',
  '#93FF33',
  '#df1c1c',
  '#48DCD0',
  '#4870DC',
  '#A448DC',
  '#137868',
  '#F79E0C',
  '#392312',
  '#ff7bd8',
];

enum EduType {
  SIN = 'SIN',
  AGM = 'AGM',
  AGS = 'AGS',
}

export const selectAcademy = (state: AppState): AcademyDataState => state.academyReducer;
export const selectAcademyData = (state: AppState): AcademyData => selectAcademy(state).academyData;

export const selectTypeList = (state: AppState): IdCodeColor[] =>
  state.scholarshipReducer.scholarshipMetadata.educationalTypes
    ?.filter(eduTypes => eduTypes.code !== EduType.SIN)
    .map((type, index) => {
      if (type.code === EduType.AGS || type.code === EduType.AGM) type.name = type.code;
      return {
        ...type,
        color: colors[index],
      };
    });
export const selectAcademyContacts = (state: AppState): AcademyContactData[] =>
  selectAcademy(state).academyContactsList;
export const selectAcademyContact = (state: AppState): AcademyContactData => selectAcademy(state).contactData;
export const selectAcademyFormatedData = (state: AppState): AcademyFormatedData => ({
  ...state.academyReducer.academyData,
  educational_codes: state.academyReducer.academyData.educational_types?.map(types => types.code),
});
