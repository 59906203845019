import { all, putResolve } from '@redux-saga/core/effects';
import { Await, setToken, _token } from '../api/api';
import { getUserDetails } from '../api/auth';
import { getUserPermissions } from '../api/permissions';
import aboutUsSagas from './aboutUs/sagas';
import areasSagas from './area/sagas';
import { disableInit, enableInit, getDetailsOk } from './auth/actions';
import authSagas from './auth/sagas';
import calendarScholarshipSaga from './calendar-scholarship/sagas';
import calendarSagas from './calendar/sagas';
import translationSagas from './translations/sagas';
import accountingSagas from './accounting/sagas';
import entitySagas from './entity/sagas';
import interviewsSagas from './interviews/sagas';
import customField from './kpis/sagas';
import { getGenericMetadata } from './metadata/actions';
import metadataSagas from './metadata/sagas';
import { getNotificationsStartPolling } from './notification/actions';
import notificationsSagas from './notification/sagas';
import { permissionsListOk } from './permissions/actions';
import permissionSagas from './permissions/sagas';
import projectSagas from './project/sagas';
import registerSagas from './register/sagas';
import scholarshipSagas from './scholarship/sagas';
import searchSagas from './searchBar/sagas';
import userSagas from './user/sagas';
import eventSagas from './event/sagas';
import serviceSagas from './service/sagas';
import trackingSagas from './tracking/sagas';
import academySagas from './academy/sagas';
import { getCategoriesTkeysOk } from './translations/actions';
import { getTranslationKeys } from '../api/translationKeys';
import internalProjectSagas from './internal-projects/sagas';
import internalProjectTemplateSagas from './internal-project-templates/sagas';
import subareaInternalProjectTypeSagas from './subareaInternalProjectType/sagas';
import getParticipants from './internal-projects/participants/list/sagas';
import getParticipantParticularitiesFromSaga from './internal-projects/participants/particularities/sagas';
import createParticipantFromSaga from './internal-projects/participants/create/sagas';
import updateParticipantFromSaga from './internal-projects/participants/update/sagas';
import educatorSagas from './internal-projects/educators/sagas';
import deleteParticipantFromSaga from './internal-projects/participants/delete/sagas';
import unsubscribeParticipantFromSaga from './internal-projects/participants/unsubscribe/sagas';
import participantsAssistanceSaga from './internal-projects/assistance/sagas';
import signatureDocumentSaga from './signature-document/sagas';
import createFamilyIncomeSaga from './scholarship/familyIncomes/create/sagas';
import deleteFamilyIncomeSaga from './scholarship/familyIncomes/delete/sagas';
import updateFamilyIncomeSaga from './scholarship/familyIncomes/update/sagas';
import scholarshipJustificationsSaga from './scholarship/justifications/sagas';
import scholarshipTransitionsSaga from './scholarship/transitions/sagas';
import scholarshipChangeStateSaga from './scholarship/changeState/sagas';
import scholarshipPaymentsSaga from './scholarship/payments/sagas';
import scholarshipPaymentAdjustmentsSaga from './scholarship/paymentAdjustments/sagas';
import entityAgreementSaga from './entity/agreement/sagas';
import paymentSettingSaga from './paymentSettings/sagas';
import scholarshipAgreementSaga from './scholarship/agreement/sagas';

function* initialSaga() {
  yield putResolve(enableInit());
  if (document.location.search) {
    const urlParams = new URLSearchParams(document.location.search);
    const token = urlParams.get('token');
    setToken(token);
  }
  if (_token) {
    try {
      const dataUserDetails = yield getUserDetails();
      const { data } = yield getUserPermissions();
      const tKeysData = (yield getTranslationKeys(dataUserDetails.data.language)) as Await<
        ReturnType<typeof getTranslationKeys>
      >;
      yield putResolve(getCategoriesTkeysOk(tKeysData));
      yield putResolve(getDetailsOk(dataUserDetails));
      yield putResolve(permissionsListOk(data));
      yield putResolve(getNotificationsStartPolling());
      yield putResolve(getGenericMetadata());
    } catch (e) {
      yield localStorage.removeItem('token');
    }
  }
  yield putResolve(disableInit());
}

export default function* rootSaga() {
  yield all([
    initialSaga(), // Captures @@INIT Action
    ...authSagas,
    ...areasSagas,
    ...userSagas,
    ...entitySagas,
    ...notificationsSagas,
    ...registerSagas,
    ...permissionSagas,
    ...scholarshipSagas,
    ...interviewsSagas,
    ...calendarSagas,
    ...calendarScholarshipSaga,
    ...searchSagas,
    ...projectSagas,
    ...aboutUsSagas,
    ...metadataSagas,
    ...customField,
    ...eventSagas,
    ...translationSagas,
    ...accountingSagas,
    ...serviceSagas,
    ...trackingSagas,
    ...academySagas,
    ...internalProjectSagas,
    ...internalProjectTemplateSagas,
    ...subareaInternalProjectTypeSagas,
    getParticipantParticularitiesFromSaga,
    ...getParticipants,
    deleteParticipantFromSaga,
    createParticipantFromSaga,
    updateParticipantFromSaga,
    unsubscribeParticipantFromSaga,
    ...educatorSagas,
    ...participantsAssistanceSaga,
    ...signatureDocumentSaga,
    createFamilyIncomeSaga,
    deleteFamilyIncomeSaga,
    updateFamilyIncomeSaga,
    ...scholarshipJustificationsSaga,
    ...scholarshipTransitionsSaga,
    ...scholarshipChangeStateSaga,
    ...scholarshipPaymentsSaga,
    ...scholarshipPaymentAdjustmentsSaga,
    ...entityAgreementSaga,
    ...paymentSettingSaga,
    ...scholarshipAgreementSaga,
  ]);
}
