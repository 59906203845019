/* eslint-disable @typescript-eslint/no-explicit-any */
import assertUnreachable from '../../helpers/assertUnreachable';
import { getDocs } from '../../helpers/getDocs';
import { AcademyDataConsts } from './action_types';
import { AcademyContactData, AcademyDataAction, AcademyIban, initialState } from './definitions';

export type AcademyDataState = typeof initialState;

const ibanReducer = (state: AcademyIban, action: AcademyDataAction): AcademyIban => {
  switch (action.type) {
    case AcademyDataConsts.SET_ACADEMY_LIST_IBAN:
      const { key: reg_key, value: reg_value, id } = action;

      if (state.id === id) {
        return {
          ...state,
          [reg_key]: reg_value,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

const academyReducer = (state = initialState, action: AcademyDataAction): AcademyDataState => {
  switch (action.type) {
    case AcademyDataConsts.GET_ACADEMY_CONTACTS:
    case AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST:
    case AcademyDataConsts.UPDATE_ACADEMY_CONTACT:
    case AcademyDataConsts.ADD_ACADEMY_CONTACT:
    case AcademyDataConsts.REMOVE_ACADEMY_CONTACT:
      return { ...state, loadingContactsList: true };
    case AcademyDataConsts.GET_ACADEMY_CONTACT:
      return {
        ...state,
        loadingModal: true,
      };
    case AcademyDataConsts.GET_ACADEMY_DATA:
    case AcademyDataConsts.ADD_ACADEMY_IBAN:
    case AcademyDataConsts.EDIT_ACADEMY_IBAN:
      return {
        ...state,
        loading: true,
        loadingIbanTab: true,
      };
    case AcademyDataConsts.OPEN_CONTACT_MODAL:
      return {
        ...state,
        contactModalView: true,
      };
    case AcademyDataConsts.CLOSE_CONTACT_MODAL:
      return {
        ...state,
        contactModalView: false,
      };
    case AcademyDataConsts.DELETE_CONTACT_DATA:
      return {
        ...state,
        contactData: {} as AcademyContactData,
      };
    case AcademyDataConsts.LIST_ACADEMY_IBANS:
      return {
        ...state,
        loadingIbanTab: true,
      };
    case AcademyDataConsts.REMOVE_ACADEMY_IBAN:
    case AcademyDataConsts.GET_ACADEMY_IBAN:
      return {
        ...state,
        loadingModal: true,
      };
    case AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT:
      return { ...state };
    case AcademyDataConsts.GET_ACADEMY_DATA_OK:
      return {
        ...state,
        loading: false,
        academyData: action.academyData,
      };
    case AcademyDataConsts.EDIT_ACADEMY_IBAN_OK:
      const result = state.academyIbans.map(i => {
        if (i.id === action.academyIban.id) return action.academyIban;
        return i;
      });
      return {
        ...state,
        loading: false,
        ibanModalView: false,
        ibanData: {} as AcademyIban,
        academyIbans: result,
        validationErrors: null,
      };
    case AcademyDataConsts.ADD_ACADEMY_IBAN_OK:
      return {
        ...state,
        loading: false,
        loadingIbanTab: false,
        ibanModalView: false,
        ibanData: {} as AcademyIban,
        academyIbans: [...state.academyIbans, action.academyIban],
        validationErrors: null,
      };
    case AcademyDataConsts.REMOVE_ACADEMY_IBAN_OK:
      return {
        ...state,
        academyIbans: state.academyIbans.filter((iban: AcademyIban) => iban.id !== action.academyIbanId),
        loadingIbanTab: false,
      };
    case AcademyDataConsts.LIST_ACADEMY_IBANS_OK:
      return {
        ...state,
        loadingIbanTab: false,
        academyIbans: action.academyIbans,
      };
    case AcademyDataConsts.GET_ACADEMY_IBAN_OK:
      return {
        ...state,
        loadingModal: false,
        ibanData: action.academyIban,
      };
    case AcademyDataConsts.SET_IBAN_DATA:
      const { key: c_key, value: c_value } = action;
      return {
        ...state,
        ibanData: {
          ...state.ibanData,
          [c_key]: c_value,
        },
      };
    case AcademyDataConsts.CLEAR_IBAN_DATA:
      return {
        ...state,
        ibanData: {} as AcademyIban,
      };
    case AcademyDataConsts.IMPORT_ACADEMIES:
    case AcademyDataConsts.IMPORT_ACADEMIES_OK:
      return state;

    case AcademyDataConsts.GET_ACADEMY_CONTACTS_OK:
    case AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST_OK:
      return {
        ...state,
        loadingContactsList: false,
        error: null,
        academyContactsList: action.academyContactsList,
      };

    case AcademyDataConsts.GET_ACADEMY_CONTACT_OK:
      return {
        ...state,
        loadingContactsList: false,
        error: null,
        loadingModal: false,
        contactData: action.contactData,
      };
    case AcademyDataConsts.UPDATE_ACADEMY_CONTACT_OK:
      const { contactData } = action;
      return {
        ...state,
        academyContactsList: state.academyContactsList.map((contact: AcademyContactData) =>
          contact.id === contactData.id ? contactData : contact,
        ),
        loadingContactsList: false,
        error: null,
        loadingModal: false,
      };
    case AcademyDataConsts.ADD_ACADEMY_CONTACT_OK:
      state.academyContactsList.push(action.contactData);
      return {
        ...state,
        loadingContactsList: false,
      };

    case AcademyDataConsts.REMOVE_ACADEMY_CONTACT_OK:
      return {
        ...state,
        academyContactsList: state.academyContactsList.filter((s: any) => s.id !== action.staff_id),
        loadingContactsList: false,
      };
    case AcademyDataConsts.GET_ACADEMY_DATA_KO:
    case AcademyDataConsts.IMPORT_ACADEMIES_KO:
    case AcademyDataConsts.GET_ACADEMY_CONTACTS_KO:
    case AcademyDataConsts.GET_ACADEMY_CONTACT_KO:
    case AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST_KO:
    case AcademyDataConsts.REMOVE_ACADEMY_IBAN_KO:
    case AcademyDataConsts.ADD_ACADEMY_IBAN_KO:
    case AcademyDataConsts.GET_ACADEMY_IBAN_KO:
    case AcademyDataConsts.LIST_ACADEMY_IBANS_KO:
    case AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN_KO:
    case AcademyDataConsts.EDIT_ACADEMY_IBAN_KO:
    case AcademyDataConsts.UPDATE_ACADEMY_CONTACT_KO:
    case AcademyDataConsts.ADD_ACADEMY_CONTACT_KO:
    case AcademyDataConsts.REMOVE_ACADEMY_CONTACT_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
        loadingIbanTab: false,
        loadingModal: false,
        loadingContactsList: false,
      };
    case AcademyDataConsts.OPEN_IBAN_MODAL:
      return {
        ...state,
        ibanModalView: true,
        validationErrors: null,
      };
    case AcademyDataConsts.CLOSE_IBAN_MODAL:
      return {
        ...state,
        ibanModalView: false,
      };
    case AcademyDataConsts.SET_ACADEMY_LIST_IBAN:
      return {
        ...state,
        academyIbans: state.academyIbans.map(iban => ibanReducer(iban, action)),
      };
    case AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN:
    case AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN_OK:
      return {
        ...state,
      };
    case AcademyDataConsts.UPLOAD_IBAN_DOCUMENT:
    case AcademyDataConsts.REMOVE_IBAN_DOCUMENT:
      return {
        ...state,
        loadingModal: true,
      };
    case AcademyDataConsts.UPLOAD_IBAN_DOCUMENT_OK:
      const { fileData } = action;
      return {
        ...state,
        loadingModal: false,
        ibanData: {
          ...state.ibanData,
          file_id: fileData.id,
          filename: fileData.original_name,
        },
      };
    case AcademyDataConsts.REMOVE_IBAN_DOCUMENT_OK:
      return {
        ...state,
        loadingModal: false,
        ibanData: {
          ...state.ibanData,
          file_id: null,
          filename: null,
        },
      };
    case AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT_OK:
      getDocs(action.data, true, action.name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case AcademyDataConsts.UPLOAD_IBAN_DOCUMENT_KO:
    case AcademyDataConsts.REMOVE_IBAN_DOCUMENT_KO:
    case AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT_KO:
      return {
        ...state,
        loadingModal: false,
        error: action.type,
      };
    case AcademyDataConsts.CONTACT_SET_DATA:
      const { key_detail } = action;
      const { value_detail } = action;
      return {
        ...state,
        contactData: {
          ...state.contactData,
          [key_detail]: value_detail,
        },
      };
    case AcademyDataConsts.ACADEMY_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        loadingModal: false,
        validationErrors: action.validationErrors,
      };
  }

  assertUnreachable(action);
  return state;
};

export default academyReducer;
