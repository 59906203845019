import { MetadataConst } from './action_types';
import {
  AccountingMetadata,
  GenericMetadata,
  GenericMetadataAction,
  GenericMetadataKoAction,
  GenericMetadataOkAction,
  GetAccountingMetadataAction,
  GetAccountingMetadataKoAction,
  GetAccountingMetadataOkAction,
  ProjectMetadata,
  ProjectMetadataAction,
  ProjectMetadataKoAction,
  ProjectMetadataOkAction,
  ResponsibleMetadata,
  ResponsibleMetadataAction,
  ResponsibleMetadataKoAction,
  ResponsibleMetadataOkAction,
  TeamMetadata,
  TeamMetadataAction,
  TeamMetadataKoAction,
  TeamMetadataOkAction,
} from './definitions';

export function getGenericMetadata(): GenericMetadataAction {
  return {
    type: MetadataConst.GET_GENERIC,
  };
}

export function getGenericMetadataOk(genericData: GenericMetadata): GenericMetadataOkAction {
  return {
    type: MetadataConst.GET_GENERIC_OK,
    genericData,
  };
}

export function getGenericMetadataKo(error: string): GenericMetadataKoAction {
  return {
    type: MetadataConst.GET_GENERIC_KO,
    error,
  };
}

export function getProjectMetadata(): ProjectMetadataAction {
  return {
    type: MetadataConst.GET_PROJECT_METADATA,
  };
}

export function getProjectMetadataOk(projectData: ProjectMetadata): ProjectMetadataOkAction {
  return {
    type: MetadataConst.GET_PROJECT_METADATA_OK,
    projectData,
  };
}

export function getProjectMetadataKo(error: string): ProjectMetadataKoAction {
  return {
    type: MetadataConst.GET_PROJECT_METADATA_KO,
    error,
  };
}

export function getResponsibleMetadata(): ResponsibleMetadataAction {
  return {
    type: MetadataConst.GET_RESPONSIBLES,
  };
}

export function getResponsibleMetadataOk(responsibles: ResponsibleMetadata[]): ResponsibleMetadataOkAction {
  return {
    type: MetadataConst.GET_RESPONSIBLES_OK,
    responsibles,
  };
}

export function getResponsibleMetadataKo(error: string): ResponsibleMetadataKoAction {
  return {
    type: MetadataConst.GET_RESPONSIBLES_KO,
    error,
  };
}

export function getTeamMetadata(): TeamMetadataAction {
  return {
    type: MetadataConst.GET_TEAM,
  };
}

export function getTeamMetadataOk(team: TeamMetadata[]): TeamMetadataOkAction {
  return {
    type: MetadataConst.GET_TEAM_OK,
    team,
  };
}

export function getTeamMetadataKo(error: string): TeamMetadataKoAction {
  return {
    type: MetadataConst.GET_TEAM_KO,
    error,
  };
}

export function getAccountingMetadataAction(): GetAccountingMetadataAction {
  return {
    type: MetadataConst.GET_ACCOUNTING_METADATA,
  };
}

export function getAccountingMetadataOkAction(accountingData: AccountingMetadata): GetAccountingMetadataOkAction {
  return {
    type: MetadataConst.GET_ACCOUNTING_METADATA_OK,
    accountingData,
  };
}

export function getAccountingMetadataKoAction(error: string): GetAccountingMetadataKoAction {
  return {
    type: MetadataConst.GET_ACCOUNTING_METADATA_KO,
    error,
  };
}
