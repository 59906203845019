import { ButtonProps } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  sendFinalTransversalCompetencesFromApi,
  sendInitialTransversalCompetencesFromApi,
} from '../../api/Scholarship/scholarship';
import Layout from '../../components/Layout/Layout';
import Submenu, { SubmenuComponentId } from '../../components/Layout/Menus/Submenu/Submenu';
import Loading from '../../components/Loading/Loading';
import parseButtonProps from '../../helpers/buttonPropsParser';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import errorMessage from '../../helpers/errorMessage';
import { tScholarshipKey } from '../../helpers/translate';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import { AuthState } from '../../redux/auth/reducers';
import { selectAuth, selectIsNousCims } from '../../redux/auth/selectors';
import { KeyButton } from '../../redux/common/definitions';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import { scholarshipGet, setScholarshipStep, switchBlockPayment } from '../../redux/scholarship/actions';
import { getScholarshipAgreementAction } from '../../redux/scholarship/agreement/actions';
import {
  getScholarshipAllowedStatesAction,
  scholarshipChangeStateAction,
} from '../../redux/scholarship/changeState/actions';
import { AllowedStates } from '../../redux/scholarship/changeState/definitions';
import {
  RejectScholarshipData,
  ScholarshipData,
  ScholarshipStateChange,
  ScholarshipStateCode,
  SubmenuItemTypeOption,
} from '../../redux/scholarship/definitions';
import { menuOptionsByState } from '../../redux/scholarship/menuOptionsScholarship';
import {
  selectGetScholarship,
  selectIsLoading,
  selectScholarshipAllowedStates,
  selectScholarshipStep,
} from '../../redux/scholarship/selectors';
import { leaveScholarship, scholarshipStateTransitionAction } from '../../redux/scholarship/transitions/actions';
import { ScholarshipLeaveModes } from '../../redux/scholarship/transitions/definitions';
import RoleCode from '../../types/Roles/roleCode';
import AcademicYear from '../../types/Scholarships/AcademicYear';
import FollowUpType from '../../types/Scholarships/followUpType';
import { getMoreInfoButtonType, getMoreInfoButtons } from './ButtonActions/MoreInfoButtonActions';
import {
  ButtonsOptionsScholarship,
  getButtonType,
  getButtonsScholarship,
} from './ButtonActions/ScholarshipButtonActions';
import ButtonsActionsMobile from './Components/ButtonsActionsMobile';
import SubmenuItemWithComponent from './Components/SubmenuItemWithComponent';
import BackMobileMenu from './Menu/BackMobileMenu';
import MobileSubmenu from './Menu/MobileSubmenu';
import AcceptScholarshipModal from './Modals/AcceptScholarshipModal';
import ChangeStateScholarshipModals from './Modals/ChangeStateScholarshipModal';
import ChooseRejectScholarshipModals from './Modals/ChooseRejectScholarshipModals';
import GetInfoScholarshipModal from './Modals/GetInfoScholarshipModal';
import ModalHelp from './Modals/ModalHelp';
import NoValidateAndDenyScholarshipModal from './Modals/NoValidateAndDenyScholarshipModal';
import RejectButtonScholarshipModal from './Modals/RejectButtonScholarshipModal';
import TrackingScholarshipModal from './Modals/TrackingScholarshipModal';
import TransversalCompetencesScholarshipModal from './Modals/TransversalCompetencesScholarshipModal';
import ScholarshipMenuRight from './ScholarshipMenuRight/ScholarshipMenuRight';
import StateMenu from './ScholarshipMenuRight/StateMenu';
import styles from './scholarship.module.scss';

interface ScholarshipProps extends RouteComponentProps<{ id: string }> {
  institut?: boolean;
  detalle?: boolean | undefined;
  scholarshipData: ScholarshipData;
  loading: boolean;
  isNouscims: boolean;
  auth: AuthState;
  canIEdit: boolean;
  isTecBec: boolean;
  canRenounce: boolean;
  canAbandon: boolean;
  canBlockPayment: boolean;
  scholarshipStep: number;
  allowedStates: AllowedStates;
  getScholarship: (id: number) => void;
  scholarshipStateTransition: (
    id: number,
    states: ScholarshipStateChange,
    redirect?: string,
    data?: ScholarshipData | RejectScholarshipData,
  ) => void;
  scholarshipChangeState: (id: number, current_state: string, next_state: string) => void;
  switchBlockPayment: (id: number) => void;
  getScholarshipAgreement: (id: number) => void;
  leaveStatesScholarship: (
    id: number,
    redirect: string,
    leaveMode: ScholarshipLeaveModes,
    modalData: RejectScholarshipData,
  ) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  setScholarshipStep: (step: number) => void;
  getScholarshipAllowedState: (scholarship_id: number) => void;
}

const Scholarship: React.FC<ScholarshipProps> = ({
  institut,
  scholarshipData,
  loading,
  isNouscims,
  auth,
  match,
  canIEdit,
  isTecBec,
  canRenounce,
  canAbandon,
  canBlockPayment,
  scholarshipStep,
  allowedStates,
  getScholarship,
  scholarshipStateTransition,
  scholarshipChangeState,
  switchBlockPayment,
  getScholarshipAgreement,
  leaveStatesScholarship,
  showSnackbar,
  setScholarshipStep,
  getScholarshipAllowedState,
}) => {
  const id = match.params.id ? parseInt(match.params.id) : 0;
  const { steps, state_id, state_code, payment_blocked, follow_t1, follow_t2, follow_t3, total_paid } = scholarshipData;
  const role_code = auth.userInfo.role_code;
  const roleCode = new RoleCode(role_code);
  const isMobile = useWindowSize().IsMobile;

  const followUpType = new FollowUpType(scholarshipData.follow_up_type);
  const academicYear = new AcademicYear(scholarshipData.academic_year);

  const resetScroll = (ref: any) => ref.current.parentElement.scroll(0, 0);
  const [menuOptions, setMenuOptions] = useState<SubmenuComponentId[]>([]);
  const [menuSelected, setMenuSelected] = useState<SubmenuComponentId>();
  const [isEdit, setIsEdit] = useState(false);
  const [buttonsActions, setButtonsActions] = useState<ButtonProps[]>([]);
  const [buttonsMoreInfo, setButtonsMoreInfo] = useState<ButtonProps[]>([]);

  const refMain = useRef(null);
  const history = useHistory();

  const buttonSendFinalCompetences = getButtonType(KeyButton.SEND_FINAL_COMPETENCES);
  const buttonSendInitialCompetences = getButtonType(KeyButton.SEND_INITIAL_COMPETENCES);
  const buttonZinger = getMoreInfoButtonType(KeyButton.ZINGER);
  const buttonEdit = getButtonType(KeyButton.EDIT);
  const buttonSendRequest = getButtonType(KeyButton.SEND_REQUEST);
  const buttonAskDate = getButtonType(KeyButton.ASK_DATE);
  const buttonSendCorrectedInformation = getButtonType(KeyButton.SEND_CORRECTED_INFORMATION);
  const buttonBlockPayment = getButtonType(KeyButton.BLOCK_PAYMENT);

  const selectDefaultRoute = (): string => {
    if (institut) {
      if (roleCode.isEntrevistador()) return '/mis-becas';
      if (scholarshipData.study_center) return '/becas-escoles-concertades';
      return '/becas-instituto';
    }
    return '/becas-entidad';
  };

  const selectRoute = () => {
    if (ScholarshipStateCode.PENDING_INTERVIEW_VALORATION && roleCode.isEntrevistador()) {
      return '/entrevistas/lista';
    } else {
      return selectDefaultRoute();
    }
  };
  /**
   * Cambiar el estado de una beca
   * @param states
   * @param dataValues
   */
  const handleChangeState = (states: ScholarshipStateChange, dataValues?: ScholarshipData | undefined) => {
    scholarshipStateTransition(scholarshipData.id, states, selectRoute(), dataValues);
  };

  const handlescholarshipChangeState = (current_state: string, next_state: string) => {
    scholarshipChangeState(scholarshipData.id, current_state, next_state);
  };

  /**
   * Para cuando se renuncia o se abandona una beca
   * @param state
   * @param modalData
   */
  const handleRejectScholarship = (leaveMode: ScholarshipLeaveModes, modalData: RejectScholarshipData) => {
    modalData.reject_state_code =
      leaveMode === 'abandonment'
        ? ScholarshipStateCode.ABANDONMENT
        : leaveMode === 'renounce'
        ? ScholarshipStateCode.RENUNCIATION
        : ScholarshipStateCode.CLOSURE;

    leaveStatesScholarship(scholarshipData.id, history.location.pathname, leaveMode, modalData);
  };

  const bindEventsButtons = () => {
    buttonBlockPayment.onClick = () => {
      switchBlockPayment(scholarshipData.id);
    };

    buttonSendCorrectedInformation.onClick = e => {
      e.preventDefault();
      handleChangeState('s09s08', { ...scholarshipData });
    };

    buttonAskDate.onClick = () =>
      history.push({
        pathname: '/calendario-becario',
        state: { id: scholarshipData.id, redirect: selectRoute() },
      });

    buttonSendRequest.onClick = e => {
      e.preventDefault();
      handleChangeState('s01s02');
    };

    buttonSendInitialCompetences.onClick = () => {
      sendInitialTransversalCompetencesFromApi(scholarshipData.id)
        .then(() => {
          showSnackbar(tScholarshipKey('Encuesta enviada correctamente'), 'success', undefined, 5000);
        })
        .catch(error => {
          showSnackbar(errorMessage(error), 'error', undefined, 5000);
        });
    };

    // Temporarily hide final student transversal competences
    buttonSendFinalCompetences.hidden = true;
    buttonSendFinalCompetences.onClick = () => {
      sendFinalTransversalCompetencesFromApi(scholarshipData.id)
        .then(() => {
          showSnackbar(tScholarshipKey('Encuesta enviada correctamente'), 'success', undefined, 5000);
        })
        .catch(error => {
          showSnackbar(errorMessage(error), 'error', undefined, 5000);
        });
    };

    buttonZinger.onClick = () => {
      history.push({
        pathname: roleCode.isJovenInstituto() ? '/mi-perfil' : `/usuario/detalles/${scholarshipData.student_id}`,
        state: { id: scholarshipData.student_id },
      });
    };

    buttonEdit.onClick = () => {
      setIsEdit(!isEdit);
    };
  };

  useEffect(() => {
    getScholarship(id);
    getScholarshipAgreement(id);
  }, [getScholarship, getScholarshipAgreement, id]);

  useEffect(() => {
    if (scholarshipStep === 0 || scholarshipStep === -1) {
      setScholarshipStep(isMobile ? -1 : 0);
    }
  }, [isMobile, scholarshipStep, setScholarshipStep]);

  useEffect(() => {
    getScholarshipAllowedState(id);
  }, [getScholarshipAllowedState, id, state_id]);

  useEffect(() => {
    if (steps) {
      const menus = menuOptionsByState({
        stateCode: state_code,
        steps,
        roleCode,
        followUpType,
        academicYear,
        isInstitut: !!institut,
        isNouscims,
        isConfirmedWithDiff: scholarshipData.confirmed_with_diff,
        t1: !!follow_t1,
        t2: !!follow_t2,
        t3: !!follow_t3,
        step: scholarshipStep,
        scholarshipPayment: total_paid,
        isEdit,
        editInterview: scholarshipData.edit_interview,
        hasPayments: scholarshipData.total_paid > 0,
      }) as SubmenuComponentId[];
      setMenuOptions(menus);
      const menuSelected = menus.find(menu => {
        return menu.selected;
      });
      if (menuSelected) {
        setMenuSelected(menuSelected);
      }
    }
  }, [steps, isEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scholarshipData.state_code && menuSelected) {
      const options: ButtonsOptionsScholarship = {
        stateCode: scholarshipData.state_code,
        stepsComplete: scholarshipData.steps.complete,
        componentType: menuSelected.component as SubmenuItemTypeOption,
        isCustomer: roleCode.isUsuarioBeca(),
        isInterviewer: roleCode.isEntrevistador(),
        isTecBec,
        canRenounce,
        canAbandon,
        canBlockPayment,
        isNouscims,
        canIEdit,
        isInstitut: !!institut,
        isEdit,
        paymentBlocked: payment_blocked,
        canChangeState: allowedStates.length > 0,
        totalPaid: scholarshipData.total_paid,
      };
      const buttons = getButtonsScholarship(options);
      setButtonsActions(buttons);
      const moreInfo = getMoreInfoButtons(isNouscims, !!institut);
      setButtonsMoreInfo(moreInfo);
      bindEventsButtons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuSelected, scholarshipData, isEdit, steps, allowedStates]);

  const selectMenuOption = (menuId: number): void => {
    menuOptions.forEach((menuOption: SubmenuComponentId) => {
      menuOption.selected = false;
      if (menuOption.componentId === menuId) {
        menuOption.selected = true;
        setMenuSelected(menuOption);
      }
    });
  };

  const onChangeMenu = (menuId: number): void => {
    setScholarshipStep(menuId);
    if (menuId >= 0) {
      selectMenuOption(menuId);
    } else {
      setMenuSelected(undefined);
    }

    !!refMain.current && resetScroll(refMain);
  };

  return (
    <>
      {loading ? (
        <div className={styles.root}>
          <Loading big />
        </div>
      ) : (
        <Layout
          rightSubmenu={
            <ScholarshipMenuRight
              actionButtons={buttonsActions}
              moreInfoButtons={buttonsMoreInfo}
              scholarshipData={scholarshipData}
            />
          }
          leftSubmenu={
            <Submenu
              titleMenu={tScholarshipKey('DATOS DE LA BECA')}
              defaultOpen
              onSelectComponentId={onChangeMenu}
              showIcon={state_code === ScholarshipStateCode.CREATION && roleCode.isUsuarioBeca()}
              optionsMenu={menuOptions}
            />
          }
        >
          <>
            {menuSelected ? (
              <>
                <StateMenu visibility={'hideDesktop'} isInitialOpen={false} scholarshipData={scholarshipData} />
                <div className={styles.root} ref={refMain}>
                  <BackMobileMenu onChangeMenu={onChangeMenu} />
                  <SubmenuItemWithComponent
                    readOnly={!!menuSelected.readOnly}
                    isSemestral={followUpType.isBiannual()}
                    componentType={menuSelected.component as SubmenuItemTypeOption}
                    isEdit={isEdit}
                  />
                  <ButtonsActionsMobile butonsActions={parseButtonProps(buttonsActions)} />
                  <ModalHelp componentType={menuSelected.component as SubmenuItemTypeOption} />
                  <ChooseRejectScholarshipModals
                    handleRejectScholarship={handleRejectScholarship}
                    scholarshipData={scholarshipData}
                  />
                  <TrackingScholarshipModal />
                  <ChangeStateScholarshipModals
                    handleChangeScholarshipState={handlescholarshipChangeState}
                    scholarshipData={scholarshipData}
                    allowedStates={allowedStates}
                  />
                  <AcceptScholarshipModal scholarshipData={scholarshipData} handleChangeState={handleChangeState} />
                  <RejectButtonScholarshipModal
                    scholarshipData={scholarshipData}
                    handleChangeState={handleChangeState}
                  />
                  <NoValidateAndDenyScholarshipModal
                    scholarshipData={scholarshipData}
                    handleChangeState={handleChangeState}
                  />
                  <GetInfoScholarshipModal scholarshipData={scholarshipData} handleChangeState={handleChangeState} />
                  <TransversalCompetencesScholarshipModal />
                </div>
              </>
            ) : (
              <>
                <StateMenu scholarshipData={scholarshipData} />
                <MobileSubmenu
                  scholarshipData={scholarshipData}
                  menus={menuOptions as SubmenuComponentId[]}
                  onChangeMenu={onChangeMenu}
                />
              </>
            )}
          </>
        </Layout>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipData: selectGetScholarship(state),
  loading: selectIsLoading(state),
  isTecBec: selectUserCan(state)('action_scholarships_users'),
  canIEdit: selectUserCan(state)('action_scholarships_edit'),
  auth: selectAuth(state),
  isNouscims: selectIsNousCims(state),
  canRenounce: selectUserCan(state)('transition_renounce'),
  canAbandon: selectUserCan(state)('transition_abandonment'),
  canBlockPayment: selectUserCan(state)('allow_block_payment'),
  scholarshipStep: selectScholarshipStep(state),
  allowedStates: selectScholarshipAllowedStates(state),
});

//ScholarshipGetAllDispatch AboutUsDispatch
const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  getScholarship: (id: number): void => dispatch(scholarshipGet(id)),
  getScholarshipAgreement: (scholarship_id: number): void => dispatch(getScholarshipAgreementAction(scholarship_id)),
  scholarshipStateTransition: (
    id: number,
    states: ScholarshipStateChange,
    redirect?: string,
    data?: ScholarshipData | RejectScholarshipData,
  ): void => dispatch(scholarshipStateTransitionAction(id, states, redirect, data)),
  scholarshipChangeState: (id: number, current_state: string, next_state: string): void =>
    dispatch(scholarshipChangeStateAction(id, current_state, next_state)),
  switchBlockPayment: (id: number) => dispatch(switchBlockPayment(id)),
  leaveStatesScholarship: (
    id: number,
    redirect: string,
    leaveMode: ScholarshipLeaveModes,
    modalData: RejectScholarshipData,
  ): void => dispatch(leaveScholarship(id, redirect, leaveMode, modalData)),
  setScholarshipStep: (step: number) => dispatch(setScholarshipStep(step)),
  getScholarshipAllowedState: (scholarship_id: number) => dispatch(getScholarshipAllowedStatesAction(scholarship_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scholarship);
