import { Button, ButtonProps } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../components/Forms/FormContainer';
import Loading from '../../../components/Loading/Loading';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import { tFormKey, tKey } from '../../../helpers/translate';
import { EntityInfo } from '../../../redux/entity/definitions';
import { ConfigScholarshipSchema } from '../../../validations/formSchema';
import styles from '../entityData.module.scss';

interface ScholarshipConfigProps {
  leftButtons?: boolean;
  entity: EntityInfo;
  disabled?: boolean;
  loading?: boolean;
  buttonActionSave: ButtonProps;
  onSave: (data: EntityInfo) => void;
  goBack?: () => void;
  setData: (key: string, value: string | number) => void;
}

const ScholarshipConfig: React.FC<ScholarshipConfigProps> = ({
  leftButtons,
  entity,
  disabled,
  loading,
  buttonActionSave,
  onSave,
  goBack,
  setData,
}) => {
  const { register, handleSubmit } = useForm<EntityInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ConfigScholarshipSchema,
  });

  useButtonAction(buttonActionSave, handleSubmit(onSave));

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <form className={styles.form}>
          <FormContainer title={tKey('Configuración becas')}>
            <div className={styles.form__container}>
              <CheckboxCompo
                name="allow_scholarship_year_renew"
                questionText={tFormKey('Permitir renovar becas el mismo curso')}
                defaultValue={entity.allow_scholarship_year_renew}
                register={register}
                disabled={disabled}
                onChange={(value: boolean) => setData('allow_scholarship_year_renew', value ? 1 : 0)}
              />
              <CheckboxCompo
                name="study_center"
                questionText={tFormKey('Centro de estudios')}
                defaultValue={+entity.study_center}
                register={register}
                disabled={disabled}
                onChange={(value: boolean) => setData('study_center', value ? 1 : 0)}
              />
            </div>
          </FormContainer>

          {!leftButtons && (
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" startIcon={<ArrowBack />} color="primary" fullWidth onClick={goBack}>
                  {tKey('VOLVER')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  color="primary"
                  fullWidth
                  onClick={handleSubmit(onSave)}
                >
                  {tKey('Guardar')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default ScholarshipConfig;
