import { Action } from 'redux';
import { APIError, APIValidationError } from '../../api/api';
import { FeedbackAPIAction } from '../FeedbackAPI/definitions';
import { AuthUserData } from '../auth/definitions';
import {
  AddressType,
  ColumnOrderData,
  DocType,
  DocumentationItem,
  Genders,
  IdCodeNamePair,
  IdNamePair,
  ResponseData,
} from '../common/definitions';
import { EventData } from '../event/definitions';
import { ScholarshipHistoryGlobal } from '../scholarship/definitions';
import { ServiceData } from '../service/definitions';
import { TypeLang } from '../translations/definitions';
import { UserDataConsts } from './action_types';

export enum RoleGroups {
  NOUSCIMS = 'nouscims',
  ENTITAT = 'entity',
  EXTERNOS = 'foreign',
  PUBLICO = 'public',
  JOVEN = 'student',
  CENTRO_EDUCATIVO = 'academy',
  SHERPA = 'sherpa',
}

export interface Role extends IdCodeNamePair {
  group: string;
}

export interface UserRole extends Role {
  is_active: boolean;
}

export interface RolePermission {
  id: number;
  code: string;
  description: string;
  type: string;
}

export interface UserPermission extends RolePermission {
  selectable: boolean;
  selected: boolean;
}

export interface UserRoleConfig {
  user_id: number;
  user_name: string;
  user_roles: UserRole[];
  active_role_permissions: RolePermission[];
  user_permissions: UserPermission[];
}

export type UserType = 'staff' | 'student' | 'ext' | 'entity' | 'academy' | 'mailing';

export interface ScholarshipUsers {
  course: string;
  academic_course: string;
  course_category: string;
  profesional_family: string;
  studies_name: string;
  center: string;
  valoracion: number;
  enrollment_confirm: number;
  monthly_price_confirm: number;
  materials_confirm: number;
  transport_price_confirm: number;
  young_contrib_approval: number;
  other_costs_confirm: number;
  total_confirm: number;
  payment_total: number;
  adjustment_amount: number;
  agreement_number: string;
  payment_first: string;
  payment_first_done: string;
  payment_second: string;
  payment_second_done: string;
  payment_third: string;
  payment_third_done: string;
  payment_fourth: string;
  payment_fourth_done: string;
  payment_total_done: number;
  payment_first_date: string;
  payment_second_date: string;
  payment_third_date: string;
  payment_fourth_date: string;
  state: string;
}

export type UserData = Omit<AuthUserData, 'is_nouscims'> & {
  surname2: string | null;
  phone: string | null;
  avatar_file_id: number;
  birthdate: string | null;
  document_type?: DocType;
  document_number: string;
  address_type?: AddressType;
  address: string | null;
  flat: string | null;
  floor: string | null;
  room: string | null;
  staircase: string | null;
  zip_code: string | null;
  city?: string | null;
  province?: string | null;
  country?: string | null;
  account_number: string | null;
  phone2: string | null;
  email2: string | null;
  facebook?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  is_academy_staff: boolean;
  instagram?: string | null;
  youtube?: string | null;
  other_social_networks?: string | null;
  gender?: Genders;
  nationality?: string | null;
  country_origin?: string | null;
  created_at?: number;
  updated_at?: number;
  entity_id?: number;
  academy_code?: string;
  whats_app?: string | null;
  documents?: AnonymousUpdateData[];
  meet_link?: string | null;
  company_name: null | string;
  employee_role: null | string;
  current_incomes: null | number;
  contract_type: null | string;
  entity_name: null | string;
  educator_name: null | string;
  educator_mail: null | string;
  scholarships: ScholarshipUsers[];
  events: EventsUserTable[];
  services: ServicesUserTable[];
  privacy_policy: number | null;
  send_information: number;
  image_use: number;
  whatsapp_aggregation: number;
  cv_agreement: boolean;
  center_cost_channel_code: string;
  user_age: number;
  scholarshipHistory: ScholarshipHistoryGlobal;
  [key: string]: any;
  zing_network: number | null;
  language?: string;
};

export type EventsUserTable = EventData & {
  assistance: boolean;
  assistance_label: string;
  eventType: 'subscription' | 'registration';
  eventType_label: string;
  manual: number;
  manual_label: string;
};

export type ServicesUserTable = ServiceData & {
  assistance: boolean;
  assistance_label: string;
  manual: number;
  manual_label: string;
};

export type UpdateUserFormFields = Pick<
  UserData,
  | 'document_type'
  | 'academy_code'
  | 'role_id'
  | 'zing_network'
  | 'gender'
  | 'birthdate'
  | 'address_type'
  | 'city'
  | 'entity_id'
  | 'province'
  | 'country'
  | 'email'
  | 'created_at'
  | 'document_number'
  | 'name'
  | 'surname'
  | 'surname2'
  | 'phone'
  | 'address'
  | 'flat'
  | 'floor'
  | 'room'
  | 'staircase'
  | 'zip_code'
  | 'account_number'
  | 'phone2'
  | 'email2'
  | 'documents'
  | 'cv_agreement'
  | 'privacy_policy'
  | 'language'
>;

export type CreateUserFields = Omit<UpdateUserFormFields, 'account_number' | 'phone2' | 'email2'>;

export interface ChangePasswordData {
  password: string;
  password2: string;
}

export interface AnonymousUpdateData {
  file_id: number | null;
  documentation_id: number;
}

export type UserItemList = Pick<
  UserData,
  | 'id'
  | 'email'
  | 'name'
  | 'surname'
  | 'surname2'
  | 'phone'
  | 'state'
  | 'document_number'
  | 'document_type'
  | 'role'
  | 'role_code'
  | 'subscribed'
>;

export type UserZingMember = {
  id: number;
  created_at: string;
  student: string;
  educator: string;
  academic_year: string;
  state: string;
  checked: boolean;
};

// INITIAL STATE
export const initialState = {
  loading: false,
  loadingRolePermissions: false,
  userCreated: false,
  error: null as APIError | null | string,
  validationErrors: null as APIValidationError | null,
  profileData: {} as UserData,
  usersList: [] as UserItemList[],
  userAvatar: '' as string,
  documentation: [] as DocumentationItem[],
  pageSize: 5 as number,
  searchText: null as string | null,
  columnOrder: { orderDirection: 'asc', orderedColumnId: 1 } as ColumnOrderData,
  responseDeleteOne: null as any | null,
  responseData: null as ResponseData | null,
  isAllSuccess: false as boolean,
  responseValidation: {} as SuccessInterface,
  entitiesMetadata: [] as IdNamePair[],
  userRolesConfig: {} as UserRoleConfig,
};

// MY PROFILE
export interface UserProfileAction extends Action {
  type: UserDataConsts.USER_PROFILE;
}
export interface UserProfileOkAction extends Action {
  type: UserDataConsts.USER_PROFILE_OK;
  profileData: UserData;
}
export interface UserValidationErrorsAction extends Action {
  type: UserDataConsts.USER_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

// GET USER TABLE
export interface GetUserDataAction extends Action {
  type: UserDataConsts.GET_USER_DATA;
  id: number;
}

export interface GetUserDataOkAction extends Action {
  type: UserDataConsts.GET_USER_DATA_OK;
  profileData: UserData;
}

// UPDATE USER
export interface SignLopdAction extends Action {
  type: UserDataConsts.SIGN_LOPD_ACTION;
  data: UpdateUserFormFields;
}

export interface SignLopdOkAction extends Action {
  type: UserDataConsts.SIGN_LOPD_ACTION_OK;
  profileData: UserData;
}

export interface UpdateUserProfileAction extends Action {
  type: UserDataConsts.UPDATE_USER_PROFILE;
  data: UpdateUserFormFields;
}

export interface UpdateUserProfileOkAction extends Action {
  type: UserDataConsts.UPDATE_USER_PROFILE_OK;
  profileData: UserData;
}

//GET ALL USERS
export interface GetUsersAction extends Action {
  type: UserDataConsts.GET_USERS;
}

export interface GetUsersOkAction extends Action {
  type: UserDataConsts.GET_USERS_OK;
  usersList: UserItemList[];
}

export interface GetUsersKoAction extends Action {
  type: UserDataConsts.GET_USERS_KO;
  error: string;
}

// DOCUMENTATION

export interface UploadDocumentAction extends Action {
  type: UserDataConsts.UPLOAD_USER_DOCUMENT;
  documentation_id: number;
  data: File;
  force_add?: boolean;
}
export interface UploadDocumentOkAction extends Action {
  type: UserDataConsts.UPLOAD_USER_DOCUMENT_OK;
  documents: DocumentationItem[];
}

export interface UploadEducatorDocumentAction extends Action {
  type: UserDataConsts.UPLOAD_EDUCATOR_DOCUMENT;
  data: File;
  documentation_id: number;
  fakeMultiUpload?: boolean;
}
export interface UploadEducatorDocumentOkAction extends Action {
  type: UserDataConsts.UPLOAD_EDUCATOR_DOCUMENT_OK;
  document: DocumentationItem;
  documentation_id: number;
  fakeMultiUpload?: boolean;
}
export interface RemoveDocumentAction extends Action {
  type: UserDataConsts.REMOVE_USER_DOCUMENT;
  file_id: number;
}
export interface RemoveDocumentOkAction extends Action {
  type: UserDataConsts.REMOVE_USER_DOCUMENT_OK;
  documents: DocumentationItem[];
}

export interface RemoveEducatorDocumentAction extends Action {
  type: UserDataConsts.REMOVE_EDUCATOR_DOCUMENT;
  file_id: number;
}
export interface RemoveEducatorDocumentOkAction extends Action {
  type: UserDataConsts.REMOVE_EDUCATOR_DOCUMENT_OK;
  file_id: number;
}

export interface DownloadDocumentAction extends Action {
  type: UserDataConsts.DOWNLOAD_USER_DOCUMENT;
  file_id: number;
  name: string;
}
export interface DownloadDocumentOkAction extends Action {
  type: UserDataConsts.DOWNLOAD_USER_DOCUMENT_OK;
  data: Blob;
  name: string;
}

export interface GetDocumentAction extends Action {
  type: UserDataConsts.GET_USER_DOCUMENT;
  file_id: number;
  name: string;
}
export interface GetDocumentOkAction extends Action {
  type: UserDataConsts.GET_USER_DOCUMENT_OK;
  dataDoc: Blob;
  name: string;
}

// -------------- PAGESIZE

export interface UserSetPageSizeTableAction extends Action {
  type: UserDataConsts.USER_SET_PAGESIZE_TABLE_OPTIONS;
  pageSize: number;
}

// ------------ SEARCHTEXT

export interface UserSetSearchTextTableAction extends Action {
  type: UserDataConsts.USER_SET_SEARCHTEXT_TABLE_OPTIONS;
  searchText: string;
}

// --------- COLUMN ORDER

export interface UserSetColumnOrderTableAction extends Action {
  type: UserDataConsts.USER_SET_COLUMNORDER_TABLE_OPTIONS;
  columnOrder: ColumnOrderData;
}

// ---------------------- UPDATE PROFILE USER TABLE

export interface UpdateProfileUserTableAction extends Action {
  type: UserDataConsts.UPDATE_PROFILE_USER_TABLE;
  data: UpdateUserFormFields;
  id: number;
}

export interface UpdateProfileUserTableOkAction extends Action {
  type: UserDataConsts.UPDATE_PROFILE_USER_TABLE_OK;
  profileData: UserData;
}

// ---------------------- CREATE USER

export interface CreateUserAction extends Action {
  type: UserDataConsts.CREATE_USER;
  data: CreateUserFields;
}

export interface CreateUserActionOk extends Action {
  type: UserDataConsts.CREATE_USER_OK;
}

// --------------------- GET DOCUMENTATION
export interface GetUserDocumentationAction extends Action {
  type: UserDataConsts.GET_USER_DOCUMENTATION;
  id: number;
}

export interface GetUserDocumentationOkAction extends Action {
  type: UserDataConsts.GET_USER_DOCUMENTATION_OK;
  documentation: DocumentationItem[];
}

export interface GetUserDocumentationKoAction extends Action {
  type: UserDataConsts.GET_USER_DOCUMENTATION_KO;
  error: APIError;
}

export interface InitNewUserAction extends Action {
  type: UserDataConsts.INIT_NEW_USER;
}

export interface InitNewUserOkAction extends Action {
  type: UserDataConsts.INIT_NEW_USER_OK;
  documentation: DocumentationItem[];
}

export interface ChangePasswordAction extends Action {
  type: UserDataConsts.CHANGE_PASSWORD;
  data: ChangePasswordData;
}

// ACCEPT USER EDUCATOR

export interface AcceptUserEducatorAction extends Action {
  type: UserDataConsts.ACCEPT_USER_EDUCATOR;
  id: number;
}

export interface AcceptUserEducatorOkAction extends Action {
  type: UserDataConsts.ACCEPT_USER_EDUCATOR_OK;
  responseValidation: SuccessInterface;
}

export interface AcceptUserEducatorKoAction extends Action {
  type: UserDataConsts.ACCEPT_USER_EDUCATOR_KO;
  error: APIError;
}

// REJECT USER EDUCATOR

export interface RejectUserEducatorAction extends Action {
  type: UserDataConsts.REJECT_USER_EDUCATOR;
  id: number;
}

export interface RejectUserEducatorOkAction extends Action {
  type: UserDataConsts.REJECT_USER_EDUCATOR_OK;
  responseValidation: SuccessInterface;
}

export interface RejectUserEducatorKoAction extends Action {
  type: UserDataConsts.REJECT_USER_EDUCATOR_KO;
  error: APIError;
}

export interface SuccessInterface {
  success: boolean;
}

export interface GetUserEntitiesMetadata extends Action {
  type: UserDataConsts.USER_ENTITIES_METADATA;
  entity_type: 'project' | 'scholarship' | 'all';
}

export interface GetUserEntitiesMetadataOk extends Action {
  type: UserDataConsts.USER_ENTITIES_METADATA_OK;
  entitiesMetadata: IdNamePair[];
}

export interface GetUserEntitiesMetadataKo extends Action {
  type: UserDataConsts.USER_ENTITIES_METADATA_KO;
  error: string;
}

export interface UpdateProfileEducator extends Action {
  type: UserDataConsts.UPDATE_PROFILE_EDUCATOR;
  data: UserData;
}

export interface UpdateProfileEducatorOk extends Action {
  type: UserDataConsts.UPDATE_PROFILE_EDUCATOR_OK;
  data: UserData;
}

export interface CreateUserEducatorAction extends Action {
  type: UserDataConsts.CREATE_USER_EDUCATOR;
  data: CreateUserFields;
}

export interface CreateUserEducatorActionOk extends Action {
  type: UserDataConsts.CREATE_USER_EDUCATOR_OK;
}

export interface GetAvatarUserByIdAction extends Action {
  type: UserDataConsts.GET_FOREING_AVATAR;
  id: number;
}
export interface GetAvatarUserByIdOkAction extends Action {
  type: UserDataConsts.GET_FOREING_AVATAR_OK;
  userAvatar: string;
}
export interface GetAvatarUserByIdKoAction extends Action {
  type: UserDataConsts.GET_FOREING_AVATAR_KO;
  error: string;
}

export interface SetAvatarUserByIdAction extends Action {
  type: UserDataConsts.SET_FOREING_AVATAR;
  id: number;
  userAvatar: string;
}
export interface SetAvatarUserByIdOkAction extends Action {
  type: UserDataConsts.SET_FOREING_AVATAR_OK;
  userAvatar: string;
}
export interface SetAvatarUserByIdKoAction extends Action {
  type: UserDataConsts.SET_FOREING_AVATAR_KO;
  error: string;
}

export interface UserSetDataAction extends Action {
  type: UserDataConsts.USER_SET_DATA;
  key: string;
  value: string | number | boolean;
}

export interface SetUserCreatedAction extends Action {
  type: UserDataConsts.USER_SET_USER_CREATED;
  userCreated: boolean;
}

// DISABLE USERS

export interface DisableUserAction extends Action {
  type: UserDataConsts.DISABLE_USER;
  id: number;
}

export interface DisableUserOkAction extends Action {
  type: UserDataConsts.DISABLE_USER_OK;
}

export interface UserLinkAcademyAction extends Action {
  type: UserDataConsts.USER_LINK_ACADEMY;
  userId: number;
}

export interface UserLinkAcademyOkAction extends Action {
  type: UserDataConsts.USER_LINK_ACADEMY_OK;
}

export interface UserLinkAcademyKoAction extends Action {
  type: UserDataConsts.USER_LINK_ACADEMY_KO;
  error: APIError;
}

export interface GetUserRolesConfigAction extends Action {
  type: UserDataConsts.GET_USER_ROLES_CONFIG;
  user_id: number;
}
export interface GetUserRolesConfigOkAction extends Action {
  type: UserDataConsts.GET_USER_ROLES_CONFIG_OK;
  userRolesConfig: UserRoleConfig;
}

export interface ConfirmUserActiveRoleAction extends Action {
  type: UserDataConsts.CONFIRM_USER_ACTIVE_ROLE;
  user_id: number;
  role_id: number;
}
export interface ConfirmUserActiveRoleOkAction extends Action {
  type: UserDataConsts.CONFIRM_USER_ACTIVE_ROLE_OK;
}

export interface UserGenericKoAction extends Action {
  type: UserDataConsts.USER_GENERIC_KO;
  error: APIError;
}
export interface UserAddRoleAction extends Action {
  type: UserDataConsts.USER_ADD_ROLE;
  user_id: number;
  role: UserRole;
}

export interface UserAddRoleOkAction extends Action {
  type: UserDataConsts.USER_ADD_ROLE_OK;
  role: UserRole;
}

export interface UserRoleActiveAction extends Action {
  type: UserDataConsts.USER_ROLE_ACTIVE;
  role_id: number;
  is_active: boolean;
}

export interface UserRoleActiveOkAction extends Action {
  type: UserDataConsts.USER_ROLE_ACTIVE_OK;
  role_id: number;
  is_active: boolean;
  rolePermissions: RolePermission[];
}

export interface UserRemoveRoleAction extends Action {
  type: UserDataConsts.USER_REMOVE_ROLE;
  user_id: number;
  role_id: number;
}

export interface UserRemoveRoleOkAction extends Action {
  type: UserDataConsts.USER_REMOVE_ROLE_OK;
  role_id: number;
}

export interface SetUserPermissionAction extends Action {
  type: UserDataConsts.SET_USER_PERMISSION;
  user_id: number;
  permission_id: number;
  value: boolean;
}

export interface SetUserPermissionOkAction extends Action {
  type: UserDataConsts.SET_USER_PERMISSION_OK;
  permission_id: number;
  value: boolean;
}

export interface UserChangeLanguageAction extends Action {
  type: UserDataConsts.USER_CHANGE_LANGUAGE;
  language: TypeLang;
}

export interface UserChangeLanguageOkAction extends Action {
  type: UserDataConsts.USER_CHANGE_LANGUAGE_OK;
  language: TypeLang;
}

export type UserDataAction =
  | UserProfileAction
  | UserProfileOkAction
  | UserValidationErrorsAction
  | GetUserDataAction
  | GetUserDataOkAction
  | UpdateUserProfileAction
  | UpdateUserProfileOkAction
  | UploadDocumentAction
  | UploadDocumentOkAction
  | RemoveDocumentAction
  | RemoveDocumentOkAction
  | DownloadDocumentAction
  | DownloadDocumentOkAction
  | GetDocumentAction
  | GetDocumentOkAction
  | UserSetColumnOrderTableAction
  | UserSetPageSizeTableAction
  | UserSetSearchTextTableAction
  | UpdateProfileUserTableAction
  | UpdateProfileUserTableOkAction
  | CreateUserAction
  | CreateUserActionOk
  | GetUserDocumentationAction
  | GetUserDocumentationOkAction
  | GetUserDocumentationKoAction
  | InitNewUserAction
  | InitNewUserOkAction
  | ChangePasswordAction
  | AcceptUserEducatorAction
  | AcceptUserEducatorOkAction
  | AcceptUserEducatorKoAction
  | RejectUserEducatorAction
  | RejectUserEducatorOkAction
  | RejectUserEducatorKoAction
  | GetUserEntitiesMetadata
  | GetUserEntitiesMetadataOk
  | GetUserEntitiesMetadataKo
  | UpdateProfileEducator
  | UpdateProfileEducatorOk
  | CreateUserEducatorAction
  | CreateUserEducatorActionOk
  | GetAvatarUserByIdAction
  | GetAvatarUserByIdOkAction
  | GetAvatarUserByIdKoAction
  | SetAvatarUserByIdAction
  | SetAvatarUserByIdOkAction
  | SetAvatarUserByIdKoAction
  | UserSetDataAction
  | UploadEducatorDocumentAction
  | UploadEducatorDocumentOkAction
  | DisableUserAction
  | DisableUserOkAction
  | RemoveEducatorDocumentAction
  | RemoveEducatorDocumentOkAction
  | SetUserCreatedAction
  | GetUsersAction
  | GetUsersOkAction
  | GetUsersKoAction
  | SignLopdAction
  | SignLopdOkAction
  | UserLinkAcademyAction
  | UserLinkAcademyOkAction
  | UserLinkAcademyKoAction
  | GetUserRolesConfigAction
  | GetUserRolesConfigOkAction
  | ConfirmUserActiveRoleAction
  | ConfirmUserActiveRoleOkAction
  | UserGenericKoAction
  | UserAddRoleAction
  | UserAddRoleOkAction
  | UserRemoveRoleAction
  | UserRemoveRoleOkAction
  | UserRoleActiveAction
  | UserRoleActiveOkAction
  | SetUserPermissionAction
  | SetUserPermissionOkAction
  | UserChangeLanguageAction
  | UserChangeLanguageOkAction;

export type UserDataDispatch = (action: UserDataAction | FeedbackAPIAction) => void;

export type UserMailing = {
  id: number;
  name: string;
  surname: string;
  role: string;
  email: string;
  subscribed: boolean;
};
